import React, { useState } from 'react';
import { Tooltip, Typography, withStyles, Fade } from '@material-ui/core';
import { theme } from '../../../../../../../../../themes/theme';

const MAX_TOOLTIP_LENGTH = 166;

// -- styled tooltip
const StyledTooltip = withStyles({
  tooltip: {
    maxWidth: 300,
    backgroundColor: 'rgba(67, 67, 67, 0.8)',
    borderRadius: 8,
    fontSize: 12,
    padding: '8px 12px',
    boxSizing: 'border-box',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  tooltipPlacementBottom: {
    margin: '0 !important', // reduce the default gap
  },
  popper: {
    '&[x-placement*="bottom"]': {
      marginTop: '2px', // space between trigger and tooltip
    },
  },
})(Tooltip);

interface ExpandableTooltipProps {
  title: string;
  children: React.ReactNode;
}

export const ExpandableTooltip: React.FC<ExpandableTooltipProps> = ({
  title,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [tooltipHover, setTooltipHover] = useState(false);

  const handleReadMoreClick = () => {
    setExpanded(!expanded);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    if (!tooltipHover) {
      setOpen(false);
      setExpanded(false);
    }
  };

  const handleTooltipMouseEnter = () => {
    setTooltipHover(true);
    setOpen(true);
  };

  const handleTooltipMouseLeave = () => {
    setTooltipHover(false);
    setOpen(false);
    setExpanded(false);
  };

  const truncatedTitle =
    title.length > MAX_TOOLTIP_LENGTH
      ? `${title.slice(0, MAX_TOOLTIP_LENGTH)}... `
      : title;

  return (
    <StyledTooltip
      title={
        <div
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
          style={{ display: 'inline-flex', flexWrap: 'wrap' }}
        >
          <Typography
            style={{
              fontSize: 12,
              color: '#fff',
              lineHeight: 1.5,
              fontWeight: 400,
              fontFamily: theme.typography.fontFamily,
              display: 'inline',
              whiteSpace: 'pre-wrap',
            }}
          >
            {expanded ? (
              title
            ) : (
              <>
                {truncatedTitle}
                {title.length > MAX_TOOLTIP_LENGTH && (
                  <>
                    <span
                      onClick={handleReadMoreClick}
                      style={{
                        verticalAlign: 'top',
                        textTransform: 'capitalize',
                        fontSize: 12,
                        color: '#9ACFFF',
                        fontWeight: 700,
                        backgroundColor: 'transparent',
                        cursor: 'pointer',
                      }}
                    >
                      Read more
                    </span>
                  </>
                )}
              </>
            )}
          </Typography>
        </div>
      }
      placement="bottom-start"
      disableFocusListener
      disableTouchListener
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      open={open}
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
      interactive
      PopperProps={{
        // Force it to stay at the bottom, do not flip, do not constrain overflow
        modifiers: {
          flip: { enabled: false },
          preventOverflow: { enabled: false },
        },
      }}
    >
      <div onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
        {children}
      </div>
    </StyledTooltip>
  );
};
