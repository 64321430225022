import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  Fade,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  useCareGapsPanelItemStyles,
  useCareGapsPanelStyles,
} from '../CareGapsDrawer/styles';
import { theme } from '../../../../../../../themes/theme';
import {
  CareGapExtendedStatusEnum,
  CareGapMeasureStatus,
} from '../../../../../../../enum/Dashboard/careGapMeasureStatus';
import moment from 'moment';
import { hardcodingDateFormat } from '../../../../../../../sharePipe/dobFormatPipe';
import { KeyboardArrowDown } from '@material-ui/icons';
import { GetSupportingDocumentConfirmPopUp } from './GetSupportingDocumentConfirmPopUp';
import { CallAttemptCountIcon } from '../../../../../../../assets/Icons/CareGaps/CallAttemptCountIcon';
import { CareGapOptionDialog } from './components/Dialogs';
import { CareGapNoteForm } from './components/CareGapNoteForm';
import { useForm } from 'react-hook-form';
import { NotesIcon } from '../../../../../../../assets/Icons/CareGaps';
import { ExpandableTooltip } from './components/ExpandableTooltip';

export enum CareGapStatusBackend {
  //Open
  Open_Manually = 'Open',
  //Pending
  Get_Supporting_Documentation = 'Get Supporting Documentation',
  Submit_Submittal = 'Submit Submittal',
  //Closed
  Assessed_And_Not_Applicable = 'Assessed & Not Applicable',
  Closed_Manually = 'Closed',
}

const CareGapNoteOptions = {
  'Submit Supplemental': 'Submit Supplemental',
  'Assessed & Not Applicable': 'Assessed & Not Applicable',
  Closed: 'Closed',
};

export const CareGapStatusOption = (isClosedOrPendingCategory: boolean) => {
  return [
    {
      name: isClosedOrPendingCategory ? 'Reopen Care Cap' : 'Open Care Gap',
      value: 'Open',
      type: CareGapStatusBackend.Open_Manually,
    },
    {
      name: 'Get Supporting Doc',
      value: 'Get Supporting Documentation',
      type: CareGapStatusBackend.Get_Supporting_Documentation,
    },
    {
      name: 'Submit Supplemental',
      value: 'Submit Supplemental',
      type: CareGapStatusBackend.Submit_Submittal,
    },
    {
      name: 'Assessed & Not Applicable',
      value: 'Assessed & Not Applicable',
      type: CareGapStatusBackend.Assessed_And_Not_Applicable,
    },
    {
      name: 'Closed Care Gap',
      value: 'Closed',
      type: CareGapStatusBackend.Closed_Manually,
    },
  ];
};

// grouped care gaps
interface CareGapsPanelViewProps {
  filteredItems: any[];
  updateCareGapStatus: any;
  isClosedCareGapsGroup: boolean;
}

export const CareGapsPanelView = (props: CareGapsPanelViewProps) => {
  const { filteredItems, isClosedCareGapsGroup } = props;
  const [careGaps, setCareGaps] = React.useState(filteredItems);
  React.useEffect(() => {
    setCareGaps(filteredItems);
  }, [filteredItems]);
  const classes = useCareGapsPanelStyles();

  const getScrollableContainer = () => {
    return isClosedCareGapsGroup
      ? `${classes.sectionContainer} ${classes.closedCareGapsContainer} `
      : classes.sectionContainer;
  };

  return (
    <div className={classes.root} role={'presentation'}>
      <div className={getScrollableContainer()} data-container="care-gaps">
        {filteredItems.map((subItem, index) => {
          const isLast = index === filteredItems.length - 1 && isClosedCareGapsGroup;

          return (
            <div
              key={`${subItem.measureName}_${index}`}
              className={isLast ? classes.lastCareGapItem : undefined}
            >
              <CareGapPanelItem
                key={`${subItem.measureName}_${index}`}
                code={subItem.measureCode}
                name={subItem.measureName}
                desc={subItem.measureDescription}
                dueDate={subItem.dueDate}
                closedDate={subItem.closedDate}
                status={subItem.measureStatus}
                eventDate={subItem.eventDate}
                measureExtendedStatus={subItem.measureExtendedStatus}
                measureStatusCategory={subItem.measureStatusCategory}
                measureStatusDisplay={subItem.measureStatusDisplay}
                measureStatusMutable={subItem.measureStatusMutable}
                updateCareGapStatus={props.updateCareGapStatus}
                nullEventDate={subItem.nullEventDate ?? null}
                note={subItem.measureNote}
                closedBy={subItem.closedBy}
                callAttempt={subItem.callAttempt ?? 0}
                notes={subItem.notes}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface CareGapPanelItemProps {
  id?: string;
  code: string;
  name: string;
  desc: string;
  dueDate: string;
  closedDate: string;
  status: string;
  eventDate: string;
  note: string;
  measureExtendedStatus: string;
  measureStatusCategory: string;
  measureStatusDisplay: string;
  measureStatusMutable: boolean;
  updateCareGapStatus: any;
  nullEventDate: any;
  closedBy: string;
  callAttempt: number;
  notes: string;
}

enum DueType {
  overDue,
  dueIn30days,
  dueOver30days,
}

const matchMeasureExtendedStatus = (status: string) => {
  return (
    CareGapStatusOption(false).find((item: any) => item.type == status)
      ?.value ?? 'Open'
  );
};

const CareGapPanelItem = (props: CareGapPanelItemProps) => {
  const {
    id,
    code,
    name,
    desc,
    dueDate,
    closedDate,
    status,
    eventDate,
    note,
    measureExtendedStatus,
    measureStatusCategory,
    measureStatusDisplay,
    measureStatusMutable,
    nullEventDate,
    closedBy,
    callAttempt,
    notes,
  } = props;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CareGapNoteFormType>({
    defaultValues: {
      notes: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [
    careGapOptionDialogState,
    setCareGapOptionDialogState,
  ] = React.useState({} as OpenCareGapOptionDialogStateType);

  const [value, setValue] = React.useState(measureStatusDisplay);
  const [
    onOpenCallSpecialtyPopUp,
    setOnOpenCallSpecialtyPopUp,
  ] = React.useState(false);

  useEffect(() => {
    setValue(measureStatusDisplay);
  }, [measureStatusDisplay]);

  const onClickCallSpecialtyPopUpCancel = () => {
    setOnOpenCallSpecialtyPopUp(false);
    setValue(measureStatusDisplay);
  };
  const getStatusValueFromEnum = (value: string) => {
    let statusKey = '';
    for (const key in CareGapStatusBackend) {
      if (value === (CareGapStatusBackend as any)[key]) {
        statusKey = key;
        break;
      }
    }
    return statusKey;
  };
  const handleChangeFromPopUp = (saveCareAttempt: boolean) => {
    props.updateCareGapStatus(
      '',
      code,
      eventDate,
      getStatusValueFromEnum(
        CareGapStatusOption(false).find((item: any) => item.value == value)
          ?.type ?? ''
      ),
      saveCareAttempt
    );
  };

  const handleUpdateCareGapStatus = async (
    notes: string,
    newValue?: string
  ) => {
    const tmpValue = newValue ?? value;
    await props.updateCareGapStatus(
      notes,
      code,
      eventDate,
      getStatusValueFromEnum(
        CareGapStatusOption(false).find((item: any) => item.value == tmpValue)
          ?.type ?? ''
      )
    );
  };

  const handleChange = async (event: React.ChangeEvent<{ value: any }>) => {
    event.preventDefault();
    let newValue = event.target.value;
    setValue(newValue);
    if (newValue === measureStatusDisplay) {
      return;
    } else if (newValue !== 'Get Supporting Documentation') {
      if ((CareGapNoteOptions as { [key: string]: string })[newValue]) {
        setCareGapOptionDialogState({
          open: true,
          onConfirmClicked: false,
          optionId: newValue,
        });
      } else {
        await handleUpdateCareGapStatus('', newValue);
      }
    } else {
      setOnOpenCallSpecialtyPopUp(true);
    }
  };

  const classes = useCareGapsPanelItemStyles();

  function getDueType() {
    if (dueDate) {
      const formattedDate = moment(new Date(dueDate));
      if (formattedDate.isBefore(moment(), 'day')) {
        return DueType.overDue;
      }
      const currentDateAfter30day = moment().add(30, 'days');
      return formattedDate.isAfter(currentDateAfter30day, 'day')
        ? DueType.dueOver30days
        : DueType.dueIn30days;
    } else {
      return DueType.dueOver30days;
    }
  }

  function getDueDateTitle() {
    if (getDueType() === DueType.overDue) {
      return 'Overdue:';
    }
    return 'Due Date:';
  }

  function pickDueDateOrClosedDate() {
    switch (measureStatusCategory) {
      case CareGapMeasureStatus.OPEN:
      case CareGapMeasureStatus.PENDING:
        return `${getDueDateTitle()} ${dueDate
            ? hardcodingDateFormat(dueDate, 'CareGapDueDateAndClosedDate')
            : 'No Date'
          }`;
      case CareGapMeasureStatus.CLOSED:
        return closedDate
          ? `Closed Date: ${hardcodingDateFormat(
            closedDate,
            'CareGapDueDateAndClosedDate'
          )}`
          : '';
      default:
        return '';
    }
  }

  const getClosedBy = () => {
    if (
      measureExtendedStatus ===
      CareGapExtendedStatusEnum.Assessed_And_Not_Applicable ||
      measureExtendedStatus === CareGapExtendedStatusEnum.Closed_Manually
    ) {
      return closedBy ? `Closed by: ${formatClosedBy(closedBy)}` : null;
    }
    if (
      measureExtendedStatus === CareGapExtendedStatusEnum.Submit_Submittal ||
      measureExtendedStatus ===
      CareGapExtendedStatusEnum.Get_Supporting_Documentation
    ) {
      return closedBy ? `Updated by: ${formatClosedBy(closedBy)}` : null;
    }
  };

  const formatClosedBy = (str: string) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(function (name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
      })
      .join(' ');
  };

  const getTitleDateStyle = () => {
    const dueType = getDueType();
    switch (measureStatusCategory) {
      case CareGapMeasureStatus.OPEN:
      case CareGapMeasureStatus.PENDING:
        if (dueType === DueType.overDue) {
          return classes.titleDateOverDue;
        } else if (dueType === DueType.dueIn30days) {
          return classes.titleDateDueIn30days;
        } else {
          return classes.titleDateOver30days;
        }
      case CareGapMeasureStatus.CLOSED:
        if (measureStatusMutable) {
          return classes.titleDateClosedManually;
        } else {
          return classes.titleDateClosedByPayer;
        }
    }
  };

  const getNoteStyle = () => {
    const dueType = getDueType();
    switch (measureStatusCategory) {
      case CareGapMeasureStatus.OPEN:
      case CareGapMeasureStatus.PENDING:
        if (dueType === DueType.overDue) {
          return classes.NoteOverDue;
        } else if (dueType === DueType.dueIn30days) {
          return classes.NoteDueIn30days;
        } else {
          return classes.NoteOver30days;
        }
      case CareGapMeasureStatus.CLOSED:
        if (measureStatusMutable) {
          return classes.NoteClosedManually;
        } else {
          return classes.NoteClosedByPayer;
        }
    }
  };

  const handleCloseDialog = (event: React.MouseEvent | undefined) => {
    setCareGapOptionDialogState({
      open: false,
      onConfirmClicked: false,
      optionId: '',
    });
    setValue(measureStatusDisplay);
  };

  const handleConfirmDialog = async (input: CareGapNoteFormType) => {
    setCareGapOptionDialogState({
      onConfirmClicked: true,
      open: false,
      optionId: '',
    });

    await handleUpdateCareGapStatus(input.notes);
  };

  const disableSubmitButton = () => {
    if (careGapOptionDialogState.onConfirmClicked) {
      return true;
    } else {
      return errors.notes != undefined || !(watch('notes') !== '');
    }
  };

  return (
    <div>
      {onOpenCallSpecialtyPopUp ? (
        <GetSupportingDocumentConfirmPopUp
          updateStatus={handleChangeFromPopUp}
          setOpen={setOnOpenCallSpecialtyPopUp}
          onClickCancel={onClickCallSpecialtyPopUpCancel}
        />
      ) : null}
      <Card
        className={classes.root}
        variant="outlined"
        style={
          measureStatusCategory === CareGapMeasureStatus.OPEN ||
            measureStatusCategory === CareGapMeasureStatus.PENDING
            ? {
              borderTop: `7px solid ${getDueType() === DueType.overDue
                  ? 'rgba(183, 55, 62, 1)'
                  : getDueType() === DueType.dueIn30days
                    ? 'rgba(192, 83, 20, 0.5)'
                    : theme.palette.primary.main
                }`,
              background:
                getDueType() === DueType.overDue
                  ? 'rgba(255, 234, 235, 1)'
                  : getDueType() === DueType.dueIn30days
                    ? 'rgba(252, 243, 229, 1)'
                    : 'rgba(237, 246, 255, 1)',
            }
            : measureStatusMutable
              ? {
                borderTop: `7px solid ${'rgba(152, 72, 0, 1)'}`,
                background: 'rgba(252, 243, 229, 0.5)',
              }
              : {
                borderTop: `7px solid ${'rgba(182, 182, 182, 1.0)'}`,
                background: 'rgba(245, 245, 245, 1.0)',
              }
        }
      >
        <CardContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              >
                <ExpandableTooltip title={desc}>
                  <div>
                    <Typography
                      style={{
                        display: 'flex',
                        flex: 1,
                        marginRight: 50,
                        justifyContent: 'space-between',
                      }}
                      className={
                        measureStatusCategory === CareGapMeasureStatus.OPEN ||
                          measureStatusCategory === CareGapMeasureStatus.PENDING
                          ? getDueType() === DueType.overDue
                            ? classes.titleOverDue
                            : getDueType() === DueType.dueIn30days
                              ? classes.titleDueIn30days
                              : classes.titleDueAfter30days
                          : measureStatusMutable
                            ? classes.titleCloseByManually
                            : classes.titleClose
                      }
                      gutterBottom
                    >
                      {`${code} ${name}`}
                    </Typography>
                  </div>
                </ExpandableTooltip>
                {nullEventDate ? (
                  <Typography className={getTitleDateStyle()} component="p">
                    Event Date:{' '}
                    {hardcodingDateFormat(
                      eventDate,
                      'CareGapDueDateAndClosedDate'
                    )}
                  </Typography>
                ) : null}
                {note ? (
                  <Typography className={getNoteStyle()} component="p">
                    {`Note: ${note}`}
                  </Typography>
                ) : null}
                {/* user typed notes for Assessed & Not Applicable, Closed Care Gap and Submit Supplemental */}
                {notes ? (
                  <div className={classes.userTypedNotesContainer}>
                    <NotesIcon
                      className={getNoteStyle()}
                      style={{ fontSize: 16 }}
                    />
                    <Typography className={getNoteStyle()} component="p">
                      {`${notes}`}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </div>
            {measureStatusDisplay == 'Get Supporting Documentation' ? (
              <div className={classes.callAttemptContainer}>
                <div className={classes.callAttemptColumn}>
                  <div className={classes.callAttemptText}>{callAttempt}</div>
                  <CallAttemptCountIcon />
                </div>
              </div>
            ) : null}
            <div className={classes.rightColumn}>
              {measureStatusMutable ? (
                <div className={classes.selectContainer}>
                  <Select
                    variant="outlined"
                    displayEmpty
                    IconComponent={KeyboardArrowDown}
                    value={value}
                    onChange={handleChange}
                    className={
                      measureStatusCategory === CareGapMeasureStatus.CLOSED
                        ? classes.careGapStatusSelectClosed
                        : classes.careGapStatusSelect
                    }
                    renderValue={(selected: any) => {
                      const item = CareGapStatusOption(false).find(
                        (item: any) => item.value == selected
                      );

                      return <span>{item?.name || selected}</span>;
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      elevation: 2,
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: 68 * 5 + 8,
                        width: 220,
                        maxWidth: '100%',
                      },
                      PaperProps: {
                        style: {
                          // reduce space between input and menu
                          minWidth: 204,
                          marginTop: 4,
                          paddingLeft: 8,
                          paddingRight: 8,
                          borderRadius: 8,
                          fontSize: 14,
                        },
                      },
                    }}
                  >
                    {CareGapStatusOption(
                      status === CareGapMeasureStatus.CLOSE ||
                      measureStatusCategory === CareGapMeasureStatus.CLOSED ||
                      measureStatusCategory === CareGapMeasureStatus.PENDING
                    ).map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item.value}
                        classes={{
                          root: classes.listItem,
                          selected: classes.selected,
                        }}
                      >
                        <div>{item.name}</div>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ) : null}
              <div style={{ width: 10, height: 4 }} />
              <div className={classes.selectContainer}>
                <Typography className={getTitleDateStyle()}>
                  {pickDueDateOrClosedDate()}
                </Typography>
              </div>
              <div className={classes.selectContainer}>
                <Typography className={getTitleDateStyle()}>
                  {getClosedBy()}
                </Typography>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Dialogs for Assessed & Not Applicable, Closed Care Gap and Submit Supplemental */}
      <CareGapOptionDialog
        dialogState={careGapOptionDialogState}
        dialogContent={{
          id: careGapOptionDialogState.optionId,
          title: careGapOptionDialogState.optionId,
          description: (
            <CareGapNoteForm
              control={control}
              register={register}
              errors={errors}
              onSubmit={handleSubmit(handleConfirmDialog)}
              dialogId={careGapOptionDialogState.optionId}
            />
          ),
          cancelButton: (
            <Button
              id="cancel-btn"
              variant="contained"
              style={{ width: 71 }}
              className={classes.backBtn}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
          ),
          confirmButton: (
            <Button
              id="confirm-button"
              variant="contained"
              disableElevation
              className={classes.updateBtn}
              disabled={disableSubmitButton()}
              type="submit"
              onClick={handleSubmit(handleConfirmDialog)}
            >
              Confirm
            </Button>
          ),
        }}
      />
    </div>
  );
};
