import React from 'react';
import { useGetDailyCoordinationProgressDataQuery } from '../scenes/DashBoard/Home/tabs/DashBoard/index.generated';

export const useDailyCoordinationData = (panelId: string | undefined) => {
  const queryDailyCoordinationProgressData = useGetDailyCoordinationProgressDataQuery(
    {
      variables: { input: { panelId: panelId } },
    }
  );

  const rawDailyCoordinationProgressData = React.useMemo(() => {
    if (queryDailyCoordinationProgressData.data) {
      return queryDailyCoordinationProgressData.data.getStatusGraphData.result;
    } else {
      return [];
    }
  }, [queryDailyCoordinationProgressData]);

  // 'OutreachClosed', 'PlannedToWalkIn', 'ApptBooked', 'Snoozed';
  let outreachClosedArray = [];
  let plannedToWalkInArray = [];
  let apptBookedArray = [];
  let snoozedArray = [];
  let letterSentArray: any[] = [];

  for (let item of rawDailyCoordinationProgressData) {
    let outreachClosed = {
      value: item['OutreachClosed'] || 0,
      date: item.actionDate,
    };
    outreachClosedArray.push(outreachClosed);

    let plannedToWalkIn = {
      value: item['PlannedToWalkIn'] || 0,
      date: item.actionDate,
    };
    plannedToWalkInArray.push(plannedToWalkIn);

    let apptBooked = {
      value: item['ApptBooked'] || 0,
      date: item.actionDate,
    };
    apptBookedArray.push(apptBooked);

    let snoozed = {
      value: item['Snoozed'] || 0,
      date: item.actionDate,
    };
    snoozedArray.push(snoozed);

    let letterSent = {
      value: item['LetterSent'] || 0,
      date: item.actionDate,
    };
    letterSentArray.push(letterSent);
  }
  const dailyCoordinationProgressData: any = {
    outreachClosed: outreachClosedArray,
    plannedToWalkIn: plannedToWalkInArray,
    apptBooked: apptBookedArray,
    snoozed: snoozedArray,
    letterSent: letterSentArray,
  };

  return { dailyCoordinationProgressData };
};
