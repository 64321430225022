import React from 'react';
import { useCopyLabelStyles } from './Styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CopyButton } from '../CopyButton';

interface CopyLabelProps {
  label: string;
  optionalCopyText?: string;
  customClasses?: ClassNameMap<'label' | 'icon' | 'root'>;
  position?: 'left' | 'right';
}

export const LabelWithCopy = (props: CopyLabelProps) => {
  const { label, customClasses, position } = props;
  const classes = useCopyLabelStyles();

  const copyText = () => {
      if (props.optionalCopyText) {
          return props.optionalCopyText;
      } else {
          // patient id: 11111111, ecw id: 3333333
          if (label && label.includes(':')) {
              return label.split(':')[1].trim();
          }
          return label;
      }
  };

  if (position === 'left') {
    return (
      <div className={customClasses?.root || classes.root}>
        <div className={customClasses?.icon || classes.icon}>
          <CopyButton textToCopy={copyText()} />
        </div>
        <div className={customClasses?.label || classes.label}>
          <span id="label">{label}</span>
        </div>
      </div>
    );
  }
  return (
      <div className={customClasses?.root || classes.root}>
          <div className={customClasses?.label || classes.label}>
              <span id="label">{label}</span>
          </div>
          <div className={customClasses?.icon || classes.icon}>
              <CopyButton textToCopy={copyText()}/>
          </div>
      </div>
  );
};
