import React from 'react';
import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import { CopyIcon } from '../../assets/Icons/CareGaps';
import { CheckIcon } from '../../assets/Icons/CreatePanel/CheckIcon';
import { ErrorIcon } from '../../assets/Icons/CreatePanel/ErrorIcon';

interface CopyButtonProps {
  textToCopy: string;
}

export const CopyButton = (props: CopyButtonProps) => {
  const { textToCopy } = props;

  const [copied, setCopied] = React.useState<undefined | boolean>(undefined);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
    } catch (err) {
      setCopied(false);
    }
  };

  const tooltipText = () => {
    switch (copied) {
      case undefined:
        return 'Copy';
      case true:
        return 'Copied';
      case false:
        return 'Error';
      default:
        return 'Copy';
    }
  };

  const copyIcon = () => {
    switch (copied) {
      case undefined:
        return <CopyIcon />;
      case true:
        return <CheckIcon style={{ color: '#41DAAF' }} />;
      case false:
        return <ErrorIcon />;
      default:
        return <CopyIcon />;
    }
  };

  React.useEffect(() => {
    // set to default after 1s
    if (copied) {
      setTimeout(() => {
        setCopied(undefined);
      }, 1500);
    }
  }, [copied]);

  return (
    <div>
      <StyledTooltip title={tooltipText()} placement="bottom-end">
        <div
          onClick={handleCopyClick}
          aria-label="copy-button"
          style={{ backgroundColor: 'transparent', padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {copyIcon()}
        </div>
      </StyledTooltip>
    </div>
  );
};

const StyledTooltip = withStyles({
  tooltip: {
    background: 'rgba(67, 67, 67, 0.8)',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 600,
    padding: '8px 10px',
    height: 'fit-content',
    color: '#FFFFFF',
    maxWidth: 300,
  },
  popper: {
    top: '-14px !important',
    left: '4px !important',
  },
})(Tooltip);
