import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useNavigate } from 'react-router';
import {
  Maybe,
  QmDiseaseAndTagsCount,
} from '../../../../../app.types.generated';
import { EligibilityIcon } from '../../../../../assets/Icons/Dashboard/EligibilityIcon';
import { PatientsIcon } from '../../../../../assets/Icons/Dashboard/PatientsIcon';
import { PanelsIcon } from '../../../../../assets/Icons/SideNavigation';
import { CardChildren } from '../../../../../components/CardContainer';
import {
  EligibilityBarchartDataType,
  StackedBarCharts,
} from '../../../../../components/Charts/StackedBarCharts';
import { CoordinationProgressChart } from '../../../../../components/Charts/StackedBarCharts/CoordinatorProgressChart';
import { StackedHorizontalBarCharts } from '../../../../../components/Charts/StackedHorizontalBar';
import { useFeature } from '../../../../../components/FeatureFlag';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { formatNumberPipe } from '../../../../../sharePipe/formatNumberPipe';
import { theme } from '../../../../../themes/theme';
import { CardPCPList } from '../../../components/CardPCPList';
import { CardRatio } from '../../../components/CardRatio';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.secondary.light,
  },
  subClass: {
    flexGrow: 1,
    padding: theme.spacing(1.5),
    '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      padding: theme.spacing(1.5),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const barChartHeaderStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
  },
  headerText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '28px',
    color: theme.palette.grey[700],
    paddingLeft: 24,
  },
}));

interface DashBoardTabViewProps {
  numOfPatients: Maybe<number> | undefined;
  numOfAverageCompliance: Maybe<number> | undefined;
  numOfPanels: Maybe<number> | undefined;
  providerAvePerformance: Maybe<number> | undefined;
  providers: any;
  dailyEngagedPatientsAndClosedGaps: any;
  diseaseAndTagsCount: any;
  eligibilityData: EligibilityBarchartDataType;
  dailyCoordinationProgressData: any;
}

export const DashBoardTabView = (props: DashBoardTabViewProps) => {
  const {
    numOfPatients,
    numOfAverageCompliance,
    numOfPanels: numOfPanels,
    providerAvePerformance,
    providers,
    diseaseAndTagsCount,
    dailyCoordinationProgressData,
  } = props;

  const classes = useStyles();
  const barChartHeaderClasses = barChartHeaderStyles();
  const navigate = useNavigate();
  const hasFeature = useFeature('eligibilityURL');
  const useFeatureCoveringProvider = useFeature('coordination');
  const enableProviderPerformance = useFeature('providerPerformanceURL')

  const calculateDiseaseAndTagsCount = (
    diseaseAndTags: QmDiseaseAndTagsCount[]
  ) => {
    const newDiseaseAndTags = diseaseAndTags.filter(
      (item) => item.name !== 'Age65'
    );
    const countObj = newDiseaseAndTags.find((item) => item.name === 'Chronic');

    return {
      counts: {
        totalPatients: countObj?.total ?? 0,
        patients: countObj?.count ?? 0,
      },
      yAxis: newDiseaseAndTags.map((item) => item.name).reverse(),
      tags: newDiseaseAndTags
        .map((item, index) => {
          if (index !== 0) {
            return item.count;
          } else {
            return 0;
          }
        })
        .reverse(),
      chronicPatients: newDiseaseAndTags
        .map((item, index) => {
          if (index !== 0) {
            return item.total;
          } else {
            return item.count;
          }
        })
        .reverse(),
      percent: newDiseaseAndTags.map((item) => item.percent).reverse(),
    };
  };

  const [containerWidth, setContainerWidth] = React.useState(0);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (anchorRef.current) {
      setContainerWidth(anchorRef.current.offsetWidth);
    }
  }, []);

  React.useEffect(() => {
    function handleWindowResize() {
      if (anchorRef.current) {
        setContainerWidth(anchorRef.current.offsetWidth);
      }
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.subClass}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <CardChildren
            title={'Total Patients'}
            showHistory={false}
            children={
              numOfPatients !== null && numOfPatients !== undefined ? (
                <CardRatio
                  type="ratio"
                  topPadding
                  icon={PatientsIcon}
                  numeratorText={formatNumberPipe(numOfPatients, 'Loading')}
                  denominatorText={''}
                  subtitle={``}
                  ratioDisable={true}
                />
              ) : (
                <LoadingSpinner style={{ width: '100%' }} />
              )
            }
            rightFooter={
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: theme.typography.fontWeightBold,
                }}
                onClick={() => {
                  navigate('/panels?limit=10&skip=0&isViewAll=true');
                }}
              >
                View All
              </div>
            }
            height={244}
            width={4}
          />

          <CardChildren
            title={'Average Gap Compliance'}
            showHistory={false}
            rightFooter={
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: theme.typography.fontWeightBold,
                }}
                onClick={() => {
                  navigate('/care_gaps');
                }}
              >
                View All
              </div>
            }
            children={
              numOfAverageCompliance !== null &&
              numOfAverageCompliance !== undefined ? (
                <CardRatio
                  type="percentage"
                  topPadding
                  icon={EligibilityIcon}
                  averageCompliance={
                    formatNumberPipe(numOfAverageCompliance, 'Loading') ===
                    'Loading'
                      ? 'Loading'
                      : `${formatNumberPipe(numOfAverageCompliance, '0')}%`
                  }
                  subtitle={``}
                />
              ) : (
                <LoadingSpinner style={{ width: '100%' }} />
              )
            }
            height={244}
            width={4}
          />

          <CardChildren
            title={'Patient Panels'}
            showHistory={false}
            rightFooter={
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: theme.typography.fontWeightBold,
                }}
                onClick={() => {
                  navigate('/panels');
                }}
              >
                View All
              </div>
            }
            children={
              numOfPanels !== null && numOfPanels != undefined ? (
                <CardRatio
                  type="icon"
                  topPadding
                  icon={PanelsIcon}
                  iconText={formatNumberPipe(numOfPanels, 'Loading')}
                  subtitle={''}
                />
              ) : (
                <LoadingSpinner style={{ width: '100%' }} />
              )
            }
            height={244}
            width={4}
          />
          {enableProviderPerformance ? (
              <CardChildren
                  title={'Provider Performance'}
                  backdrop={useFeatureCoveringProvider != true}
                  showHistory={false}
                  leftFooter={''}
                  rightFooter={
                    <div
                        style={{
                          cursor: 'pointer',
                          fontWeight: theme.typography.fontWeightBold,
                        }}
                        onClick={() => {
                          navigate('/pcp');
                        }}
                    >
                      View All
                    </div>
                  }
                  children={
                    <div
                        ref={anchorRef}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: 336,
                        }}
                    >
                      {providers ? (
                          <>
                            <CardRatio
                                type="percentage"
                                icon={EligibilityIcon}
                                currentPct={providerAvePerformance ?? 0}
                                subtitle={`Average of ${providers.length ?? 0} Providers`}
                            />
                            <CardPCPList
                                containerWidth={containerWidth}
                                listType="PCP"
                                list={providers}
                            />
                          </>
                      ) : (
                          <>
                            <LoadingSpinner style={{ width: '100%' }} />
                          </>
                      )}
                    </div>
                  }
                  height={440}
                  width={4}
              />
          ) : null}

          <CardChildren
            backdrop={false}
            backdropContent={'New Feature Coming Soon!'}
            title={'Daily Coordination Progress Across Organization'}
            showHistory={false}
            headerStyle={barChartHeaderClasses}
            children={
              <CoordinationProgressChart
                height={360}
                data={dailyCoordinationProgressData}
              />
            }
            height={440}
            width={8}
          />
          {hasFeature ? (
            <CardChildren
              backdrop={false}
              backdropContent={'New Feature Coming Soon!'}
              title={'Patients Eligibility'}
              showHistory={false}
              headerStyle={barChartHeaderClasses}
              children={
                <StackedBarCharts height={360} data={props.eligibilityData} />
              }
              height={440}
              width={8}
              headerLink={'/eligibility'}
            />
          ) : null}

          <CardChildren
            title={'Disease and Tags'}
            showHistory={false}
            headerStyle={barChartHeaderClasses}
            children={
              diseaseAndTagsCount ? (
                <StackedHorizontalBarCharts
                  height={360}
                  data={calculateDiseaseAndTagsCount(diseaseAndTagsCount)}
                />
              ) : (
                <LoadingSpinner style={{ width: '100%', height: 384 }} />
              )
            }
            height={440}
            width={4}
          />
        </Grid>
      </div>
    </div>
  );
};
