import React from 'react';
import {
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import { StyledRadio } from '../../../../../../../../../../components/StyledRadio';
import 'date-fns';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
        '& .MuiList-padding': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    title: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '24px',
        color: '#434343',
    },
    list: {
        borderStyle: 'solid',
        border: 1,
        borderColor: '#0000001F',
        borderRadius: '8px',
        marginTop: 4,
        '& .MuiList-padding': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        '& li div.MuiListItem-root': {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    listItem: {
        minHeight: 56,
    },
    selectDateContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'flex-start',
    },
    selectDateTitle: {
        marginLeft: 4,
    },
    itemText: {
        fontSize: 14,
        lineHeight: '24px',
        color: '#656565',
    },
    textField: {
        // marginLeft: 15,
        width: 325,
        '& .MuiOutlinedInput-input': {
            fontSize: 16,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            padding: '8px 16px',
        },
    },
    formContainer: {
        flex: 'display',
        flexDirection: 'row',
    },
    errorMsg: {
        marginLeft: 50,
        fontSize: 12,
        fontWeight: 600,
        paddingTop: theme.spacing(0.5),
    },
}));

interface LetterSentOptionProps {
    outReachReason: String;
    setOutReachReason: any;
    customMessage: any;
    setCustomMessage: any;
    customMessageErr: string | undefined;
}

export const LetterSentOption = (props: LetterSentOptionProps) => {
    const classes = useStyles();

    const handleChange = (e: any) => {
        props.setCustomMessage(e.target.value);
    };

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>Letter Sent Reason</Typography>
            <List className={classes.list}>
                <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => {
                        props.setOutReachReason('Unable to reach patient');
                    }}
                >
                    <div className={classes.itemText}>Unable to reach patient</div>
                    <ListItemSecondaryAction>
                        <StyledRadio
                            value={'Unable to reach patient'}
                            checked={props.outReachReason === 'Unable to reach patient'}
                            color="primary"
                            onChange={() => {
                                props.setOutReachReason('Unable to reach patient');
                            }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => {
                        props.setOutReachReason('Not in EMR / No Prior Visit');
                    }}
                >
                    <div className={classes.itemText}>Not in EMR / No Prior Visit</div>
                    <ListItemSecondaryAction>
                        <StyledRadio
                            value={'Not in EMR / No Prior Visit'}
                            checked={props.outReachReason === 'Not in EMR / No Prior Visit'}
                            color="primary"
                            onChange={() => {
                                props.setOutReachReason('Not in EMR / No Prior Visit');
                            }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="middle" />
                <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => {
                        props.setOutReachReason('Other');
                    }}
                    style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 15,
                        }}
                    >
                        <div className={classes.itemText}>Other</div>
                        <div className={classes.formContainer}>
                            <TextField
                                multiline={true}
                                placeholder={'Type reason'}
                                value={props.customMessage}
                                variant="outlined"
                                className={classes.textField}
                                onChange={(e) => handleChange(e)}
                                error={props.customMessageErr !== undefined}
                            />
                        </div>
                        <div>
                            <StyledRadio
                                value={'Other'}
                                checked={props.outReachReason === 'Other'}
                                color="primary"
                                onChange={() => {
                                    props.setOutReachReason('Other');
                                }}
                            />
                        </div>
                    </div>

                    {props.customMessageErr && (
                        <Typography color="error" className={classes.errorMsg}>
                            {props.customMessageErr}
                        </Typography>
                    )}
                </ListItem>
            </List>
        </div>
    );
};
