import React from 'react';
import { useCareGapDrawerHeaderStyles } from './Styles';
import { CareGapDrawerHeaderTitle } from './components/CareGapDrawerHeaderTitle';
import { CareGapDrawerHeaderAction } from './components/CareGapDrawerHeaderAction';
import {
  CareTeamMember,
  PanelCoordinator,
  PanelPatient,
  PatientFaceInfo,
} from '../../../../../../../app.types.generated';
import { GlobalContext } from '../../../../../../../components/GlobalContext';
import { useParams } from 'react-router';
import { useDisabledSwitchArrowButton } from '../../hooks/useDisabledSwitchArrowButton';
import { UserDefinedRole } from '../../../../../../../enum/role';
import { KeyPatientsType } from '../../../../../../Panels/ViewPanels/PanelPatients/hooks/useKeyPatients';
import { usePatientFilterStore } from '../../../../../../../store/features/patientFilter';
import { PatientFilterComponentIDs } from '../../../../../../../enum/store';
import { CareGapContext } from '../../context';

interface CareGapDrawerHeaderViewProps {
  patientInfo: PatientFaceInfo;
  allPatients: PanelPatient[];
  setEnableChangeCoordinatorDialog?: (input: boolean) => void;
  clickedFamilyRows: boolean;
  setClickedFamilyRows: (input: boolean) => void;
  originalPatient: PanelPatient;
  setFromUserId?: (input: string) => void;
  hardRefresh: any;
  currentPage: number;
  totalCount: number;
  limit: number;
  skip: number;
  setSkip: (input: number) => void;
  setHasNext: (input: boolean) => void;
  fromCoordinators: CareTeamMember[] | null | undefined;
  setKeyPatients: React.Dispatch<React.SetStateAction<KeyPatientsType>>;
}

export const CareGapDrawerHeaderView = (
  props: CareGapDrawerHeaderViewProps
) => {
  const {
    patientInfo,
    allPatients,
    setEnableChangeCoordinatorDialog,
    clickedFamilyRows,
    setClickedFamilyRows,
    originalPatient,
    setFromUserId,
    currentPage,
    totalCount,
    limit,
    skip,
    setSkip,
    setHasNext,
    fromCoordinators,
    setKeyPatients,
  } = props;


  const classes = useCareGapDrawerHeaderStyles();
  const { patientFilterComponentId } = React.useContext(
    CareGapContext
);

const patientFilterStore = usePatientFilterStore(patientFilterComponentId ?? PatientFilterComponentIDs.ALL_PATIENTS);
const currentFilter = patientFilterStore.formattedFiltersForQuery;

  const { loggedInUser } = React.useContext(GlobalContext);
  const { id } = useParams();

  const [showCoordinator, setShowCoordinator] = React.useState(false);
  const [coordinator, setCoordinator] = React.useState({
    firstName: '',
    lastName: '',
    title: '',
    avatarUrl: '',
  });

  const {
    disabledArrowBackwardBtn,
    disabledArrowForwardBtn,
    handleSwitchNextPatient,
    handleSwitchPatient,
  } = useDisabledSwitchArrowButton(
    allPatients,
    patientInfo.id,
    currentPage,
    skip,
    setSkip,
    limit,
    totalCount,
    setKeyPatients
  );

  React.useEffect(() => {
    setHasNext(!disabledArrowForwardBtn);
  }, [disabledArrowForwardBtn]);

  React.useEffect(() => {
    const currentPanelCoordinator = patientInfo.panels?.find(
      (panel: PanelCoordinator) => panel.panelId === id
    );

    if (currentPanelCoordinator) {
      setShowCoordinator(true);
      setFromUserId && setFromUserId(currentPanelCoordinator.coordinatorId!);

      let fullName = [] as string[];
      currentPanelCoordinator.coordinatorName
        ?.split(',')
        .forEach((name: string) => fullName.push(name.trim()));

      setCoordinator({
        firstName: fullName[1],
        lastName: fullName[0],
        title:
          (fromCoordinators &&
            (fromCoordinators[0].user!.role!.roleName === UserDefinedRole.ADMIN
              ? UserDefinedRole.CARE_MANAGER
              : fromCoordinators[0].user!.role!.roleName)) ||
          '',
        avatarUrl: '',
      });
    } else {
      setShowCoordinator(false);
    }
  }, [patientInfo.panels, loggedInUser]);

  const handleClose = () => {
    // go to the first patient
    setKeyPatients((oldValue) => {
      return { ori: oldValue.ori, pre: oldValue.cur, cur: originalPatient };
    });
    setClickedFamilyRows(false);
  };

  // // release lock is done by passing preMpatientId when calling getPatientFaceInfo API
  const enhancedHandleSwitchPatient = (event: React.MouseEvent) => {
    handleSwitchPatient(event);
  };

  const enhancedHandleSwitchNextPatient = () => {
    handleSwitchNextPatient();
  };

  const getPid = () => {
    if (!patientInfo.patientId && patientInfo.id && patientInfo.id.toString().length > 10) {
      return patientInfo.id ?? '';
    }

    const clinicPatients = patientInfo.patients?.filter(({clinicId, patientId}) => {
      if (currentFilter?.clinicId?.length) {
        return currentFilter?.clinicId.includes(clinicId?.toString());
      } else {
        return true
      }
    })

    const clinicPatientPairs = clinicPatients?.map(({patientId}) => {
      return {
        clinicId: patientInfo.clinicId,
        patientId: patientId,
      }
    })

    const pid = clinicPatientPairs?.map(({clinicId, patientId}) => {
      return `${patientId}-${clinicId}`
    })

    return pid?.join(',') ?? '';
  };

  return (
    <div className={classes.drawerHeaderContainer}>
      <CareGapDrawerHeaderTitle
        patientInfo={patientInfo}
        disabledArrowForwardBtn={disabledArrowForwardBtn}
        disabledArrowBackwardBtn={disabledArrowBackwardBtn}
        handleSwitchPatient={enhancedHandleSwitchPatient}
        clickedFamilyRows={clickedFamilyRows}
        handleClose={handleClose}
      />

      <CareGapDrawerHeaderAction
        showCoordinator={showCoordinator}
        patientInfo={patientInfo}
        coordinator={coordinator}
        setEnableChangeCoordinatorDialog={setEnableChangeCoordinatorDialog}
        handleSwitchNextPatient={enhancedHandleSwitchNextPatient}
        patientId={patientInfo.id}
        hardRefresh={props.hardRefresh}
        disabledArrowForwardBtn={disabledArrowForwardBtn}
        pid={getPid()}
      />
    </div>
  );
};
