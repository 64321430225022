import * as Types from '../../../../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetAverageComplianceQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetAverageComplianceQuery = (
  { __typename: 'Query' }
  & { getAverageCompliance?: Types.Maybe<(
    { __typename: 'QMAverageCompliance' }
    & Pick<Types.QmAverageCompliance, 'OpenGaps' | 'ClosedGaps' | 'TotalGaps' | 'AverageCompliance'>
  )> }
);

export type GetDashboardQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDashboardQuery = (
  { __typename: 'Query' }
  & { dashboard: (
    { __typename: 'Dashboard' }
    & Pick<Types.Dashboard, 'roleName' | 'patientCount' | 'averageCompliance' | 'panelCount'>
    & { last10Days?: Types.Maybe<(
      { __typename: 'Last10DayCountResponse' }
      & Pick<Types.Last10DayCountResponse, 'engagedPatients' | 'dates' | 'closedGaps'>
    )>, pcps?: Types.Maybe<(
      { __typename: 'PcpFieldResponse' }
      & Pick<Types.PcpFieldResponse, 'averagePerformance'>
      & { pcps: Array<(
        { __typename: 'SPCPPerformance' }
        & Pick<Types.SpcpPerformance, 'fullName' | 'performance' | 'lastName' | 'firstName'>
      )> }
    )>, disease?: Types.Maybe<Array<(
      { __typename: 'SDiseaseAndTagsCount' }
      & Pick<Types.SDiseaseAndTagsCount, 'count' | 'name' | 'percent' | 'total'>
    )>> }
  ) }
);

export type GetDailyCoordinationProgressDataQueryVariables = Types.Exact<{
  input: Types.GraphInput;
}>;


export type GetDailyCoordinationProgressDataQuery = (
  { __typename: 'Query' }
  & { getStatusGraphData: (
    { __typename: 'GraphResponse' }
    & { result: Array<(
      { __typename: 'GraphStatusData' }
      & Pick<Types.GraphStatusData, 'OutreachClosed' | 'PlannedToWalkIn' | 'ApptBooked' | 'Snoozed' | 'actionDate' | 'LetterSent'>
    )> }
  ) }
);


export const GetAverageComplianceDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAverageCompliance"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAverageCompliance"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"panelId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"OpenGaps"}},{"kind":"Field","name":{"kind":"Name","value":"ClosedGaps"}},{"kind":"Field","name":{"kind":"Name","value":"TotalGaps"}},{"kind":"Field","name":{"kind":"Name","value":"AverageCompliance"}}]}}]}}]};

/**
 * __useGetAverageComplianceQuery__
 *
 * To run a query within a React component, call `useGetAverageComplianceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAverageComplianceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAverageComplianceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAverageComplianceQuery(baseOptions?: Apollo.QueryHookOptions<GetAverageComplianceQuery, GetAverageComplianceQueryVariables>) {
        return Apollo.useQuery<GetAverageComplianceQuery, GetAverageComplianceQueryVariables>(GetAverageComplianceDocument, baseOptions);
      }
export function useGetAverageComplianceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAverageComplianceQuery, GetAverageComplianceQueryVariables>) {
          return Apollo.useLazyQuery<GetAverageComplianceQuery, GetAverageComplianceQueryVariables>(GetAverageComplianceDocument, baseOptions);
        }
export type GetAverageComplianceQueryHookResult = ReturnType<typeof useGetAverageComplianceQuery>;
export type GetAverageComplianceLazyQueryHookResult = ReturnType<typeof useGetAverageComplianceLazyQuery>;
export type GetAverageComplianceQueryResult = Apollo.QueryResult<GetAverageComplianceQuery, GetAverageComplianceQueryVariables>;
export function refetchGetAverageComplianceQuery(variables?: GetAverageComplianceQueryVariables) {
      return { query: GetAverageComplianceDocument, variables: variables }
    }
export const GetDashboardDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getDashboard"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dashboard"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"roleName"}},{"kind":"Field","name":{"kind":"Name","value":"last10Days"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"engagedPatients"}},{"kind":"Field","name":{"kind":"Name","value":"dates"}},{"kind":"Field","name":{"kind":"Name","value":"closedGaps"}}]}},{"kind":"Field","name":{"kind":"Name","value":"patientCount"}},{"kind":"Field","name":{"kind":"Name","value":"averageCompliance"}},{"kind":"Field","name":{"kind":"Name","value":"panelCount"}},{"kind":"Field","name":{"kind":"Name","value":"pcps"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pcps"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"performance"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"averagePerformance"}}]}},{"kind":"Field","name":{"kind":"Name","value":"disease"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"percent"}},{"kind":"Field","name":{"kind":"Name","value":"total"}}]}}]}}]}}]};

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardQuery(baseOptions?: Apollo.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
        return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, baseOptions);
      }
export function useGetDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, baseOptions);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export function refetchGetDashboardQuery(variables?: GetDashboardQueryVariables) {
      return { query: GetDashboardDocument, variables: variables }
    }
export const GetDailyCoordinationProgressDataDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getDailyCoordinationProgressData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GraphInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getStatusGraphData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"OutreachClosed"}},{"kind":"Field","name":{"kind":"Name","value":"PlannedToWalkIn"}},{"kind":"Field","name":{"kind":"Name","value":"ApptBooked"}},{"kind":"Field","name":{"kind":"Name","value":"Snoozed"}},{"kind":"Field","name":{"kind":"Name","value":"actionDate"}},{"kind":"Field","name":{"kind":"Name","value":"LetterSent"}}]}}]}}]}}]};

/**
 * __useGetDailyCoordinationProgressDataQuery__
 *
 * To run a query within a React component, call `useGetDailyCoordinationProgressDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyCoordinationProgressDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyCoordinationProgressDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDailyCoordinationProgressDataQuery(baseOptions: Apollo.QueryHookOptions<GetDailyCoordinationProgressDataQuery, GetDailyCoordinationProgressDataQueryVariables>) {
        return Apollo.useQuery<GetDailyCoordinationProgressDataQuery, GetDailyCoordinationProgressDataQueryVariables>(GetDailyCoordinationProgressDataDocument, baseOptions);
      }
export function useGetDailyCoordinationProgressDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailyCoordinationProgressDataQuery, GetDailyCoordinationProgressDataQueryVariables>) {
          return Apollo.useLazyQuery<GetDailyCoordinationProgressDataQuery, GetDailyCoordinationProgressDataQueryVariables>(GetDailyCoordinationProgressDataDocument, baseOptions);
        }
export type GetDailyCoordinationProgressDataQueryHookResult = ReturnType<typeof useGetDailyCoordinationProgressDataQuery>;
export type GetDailyCoordinationProgressDataLazyQueryHookResult = ReturnType<typeof useGetDailyCoordinationProgressDataLazyQuery>;
export type GetDailyCoordinationProgressDataQueryResult = Apollo.QueryResult<GetDailyCoordinationProgressDataQuery, GetDailyCoordinationProgressDataQueryVariables>;
export function refetchGetDailyCoordinationProgressDataQuery(variables?: GetDailyCoordinationProgressDataQueryVariables) {
      return { query: GetDailyCoordinationProgressDataDocument, variables: variables }
    }