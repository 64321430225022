import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import {
  CheckIsAdmin, CheckIsBasithAccount,
  CheckIsCareManager,
  CheckIsDemoSiteProfessionalAccount,
  CheckIsDemoSiteProfessionalClinician,
  CheckIsExcelsiorAccount, CheckIsHaqueAccount,
  CheckIsHudsonAccount,
  CheckIsHudsonClinician, CheckIsMamoonAccount, CheckIsUatProfessionalAccount, CheckIsZakiaAccount,
} from '../../../components/CheckIsAdmin';
import { GlobalContext } from '../../../components/GlobalContext';
import { HomePageHeader } from '../../../components/HomePageHeader';
import { Loading } from '../../../components/Loading';
import { MainRegion } from '../../../components/MainRegion';
import PowerBI from '../../../components/PowerBI';
import {config} from "../../../config";
import { DashBoardHomeTab } from './tabs/DashBoard';
import { DashBoardViewCC } from './tabs/DashBoardCC';
import { PowerBIUnavailableError } from '../../../components/PowerBIError';
import { Grid } from '@material-ui/core';
import { TabBar } from '../../../components/TabBar';
import { useFeature } from '../../../components/FeatureFlag';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    '& div[role="tabpanel"]:last-child > div': {
      height: '100%',
    },

    '& .MuiGrid-root': {
      height: '100%',
    },
  },
  control: {
    margin: 0,
    padding: 0,
    boxShadow: 'none',
  },

  deactiveTab: {
    color: theme.palette.grey[600],
  },
  activeTab: {
    color: theme.palette.info.main,
  },
  tabText: {
    height: '24px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
    fontFamily: 'Manrope',
    textTransform: 'capitalize',
  },
  tabNum: {
    display: 'inline-block',
    borderRadius: '6px',
    margin: '0 4px',
    padding: '0 5px',
  },
  activeNum: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.info.main,
  },
  deactiveNum: {
    color: theme.palette.grey[500],
    backgroundColor: '#efefef',
  },

  headerBreadcrum: {
    display: 'flex',
    height: '32px',
    marginTop: '10px',
    padding: '6px 24px',

    '& .MuiSvgIcon-root': {
      color: theme.palette.text.disabled,
    },
  },
  breadcrumbRoot: {
    color: theme.palette.text.secondary,
    height: '20px',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '0px',
    lineHeight: '20px',
    borderBottom: `2px solid ${theme.palette.text.primary}`,
    textTransform: 'capitalize',
  },
  breadcrumbChildren: {
    color: theme.palette.text.secondary,
    height: '24px',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0px',
    lineHeight: '24px',
    textTransform: 'capitalize',
    // display: 'inline-block',
  },
  headerMainRow: {
    height: 88,
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',

    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: '32px',
      fontWeight: 'bold',
      height: '40px',
      letterSpacing: '0',
      lineHeight: '40px',
      // margin: '18.68px 24px',
      margin: '24px',
      display: 'inline-block',
      textTransform: 'capitalize',
    },

    '& #last-update-container': {
      marginLeft: 'auto',
      marginRight: '24px',
      display: 'flex',
      flexDirection: 'row',
    },

    '& #creation-button': {
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      lineHeight: '24px',
      background: theme.palette.primary.main,
      borderRadius: '8px',
      width: '125px',
      height: '40px',
      textAlign: 'center',
      padding: '0',

      '&:hover': {
        backgroundColor: '#1b8cf3',
      },
    },

    '& .MuiButton-label span': {
      color: theme.palette.primary.contrastText,
      fontSize: '14px',
      fontWeight: 'bold',
      letterSpacing: '0',
      textTransform: 'capitalize',
      marginLeft: '3.33px',
    },
  },

  icon: {
    width: '24px',
    height: '24px',
    color: theme.palette.text.disabled,
  },
}));

const tabObjects = [
  {
    tabIndex: 0,
    tabLabel: 'Home',
    tabUrl: '',
    tabBadgeCount: null,
    disabled: false,
  },
  {
    tabIndex: 1,
    tabLabel: 'VBC Dashboard',
    tabUrl: '',
    tabBadgeCount: null,
    disabled: false,
  },
];

export const DashBoardHomeView = (props: any) => {
  const hasVBCDashboard = useFeature('vbcDashboard');
  const { loggedInUser, loading } = useContext(GlobalContext);
  const classes = useStyles();

  const [tabIdxValue, setTabIdxValue] = React.useState(0);
  const switchTab = (event: any, newValue: number) => {
    setTabIdxValue(newValue);
  };

  const renderedDashboard = React.useMemo(() => {
    if (!loggedInUser) {
      return <></>;
    }
    if (
      CheckIsHudsonClinician(loggedInUser) ||
      CheckIsDemoSiteProfessionalClinician(loggedInUser)
    ) {
      if (!loggedInUser.clinicId || !loggedInUser.npi) {
        return <PowerBIUnavailableError />;
      }
      // the Optum PowerBI is only available for Hudson Clinician
      return <PowerBI />;
    } else {
      // read the config from config.ts
        // if the VBC Dashboard is enabled, show the VBC Dashboard
     const accountId = loggedInUser.accountId;
     const msoFeature = config.featureListByAccountId;
     let isEnableVBCDashboard = false
      Object.entries(msoFeature).forEach(([key, value]) => {
        if ((key + "") === accountId) {
          console.log(`Key: ${key}, Value: ${JSON.stringify(value)}`);
          console.log(`VBC Dashboard: ${JSON.parse(JSON.stringify(value)).vbcDashboard}`);
          isEnableVBCDashboard = JSON.parse(JSON.stringify(value)).vbcDashboard;
        }
      });

      if (isEnableVBCDashboard) {
        if (
            CheckIsDemoSiteProfessionalAccount(loggedInUser) ||
            CheckIsHudsonAccount(loggedInUser) ||
            CheckIsExcelsiorAccount(loggedInUser) ||
            CheckIsUatProfessionalAccount(loggedInUser) ||
            CheckIsZakiaAccount(loggedInUser) ||
            CheckIsHaqueAccount(loggedInUser) ||
            CheckIsBasithAccount(loggedInUser) ||
            CheckIsMamoonAccount(loggedInUser)
        ) {
          // MSO Admin only
          // but using Hudson account for demo in UAT, don't show the VBC Dashboard in PROD
          // Update: Hudson and Excelsior account will have the VBC Dashboard in PROD 2024-10-09 release
          if (CheckIsAdmin(loggedInUser)) {
            return (
              <Grid container className={classes.root}>
                <TabBar
                  sticky={true}
                  activeTabIndex={tabIdxValue}
                  switchTab={switchTab}
                  tabObjects={tabObjects}
                >
                  <TabBarElement
                    tabObjects={tabObjects}
                    loggedInUser={loggedInUser}
                    selectedIndex={tabIdxValue}
                  />
                </TabBar>
              </Grid>
            );
          }
        }
      }

      return <DashboardHome loggedInUser={loggedInUser} />;
    }
  }, [loggedInUser, tabIdxValue]);

  if (loading) {
    return <Loading />;
  }
  return (
    <MainRegion
      header={
        <HomePageHeader
          headerTitle={`Welcome, ${loggedInUser?.firstName || ''}`}
          headerActionElement={<div></div>}
          privilege={{
            checkPrivilege: false,
            neededPrivileges: '',
          }}
        />
      }
    >
      {renderedDashboard}
    </MainRegion>
  );
};
interface TabBarElementProps {
  tabObjects: any;
  loggedInUser: any;
  selectedIndex: number;
}

const TabBarElement: React.FC<TabBarElementProps> = ({
  tabObjects,
  loggedInUser,
  selectedIndex,
}) => {
  // Define the logic for each tab in a map
  const tabContentMap: { [key: string]: JSX.Element } = {
    Home: <DashboardHome loggedInUser={loggedInUser} />,
    'VBC Dashboard': <DashboardVBC />,
  };

  const selectedTabLabel = tabObjects[selectedIndex]?.tabLabel;

  return <>{selectedTabLabel ? tabContentMap[selectedTabLabel] : null}</>;
};

interface DashBoardHomeProps {
  loggedInUser: any;
}

const DashboardHome: React.FC<DashBoardHomeProps> = ({ loggedInUser }) => {
  return (
    <Grid item xs={12} key="Home">
      {CheckIsAdmin(loggedInUser) || CheckIsCareManager(loggedInUser) ? (
        <DashBoardHomeTab />
      ) : (
        <DashBoardViewCC />
      )}
    </Grid>
  );
};

interface DashBoardVBCProps {
  // loggedInUser: any;
}
const DashboardVBC: React.FC<DashBoardVBCProps> = (
  {
    // loggedInUser: { clinicId, npi },
  }
) => {
  return (
    <Grid item xs={12} key="VBC Dashboard">
      <PowerBI />
    </Grid>
  );
};
