import { makeStyles } from '@material-ui/core';
import { theme } from '../../../../../../../themes/theme';

export const useCareGapsDrawerTabsStyles = makeStyles({
  root: {
    position: 'relative',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  control: {
    margin: 0,
    padding: 0,
    boxShadow: 'none',
    '& > div > div:first-child': {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: 'border-box',
    },
    '& div[role="tabpanel"]': {
      background: theme.palette.background.default,
    },
  },
});

export const useCareGapDrawerStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
    '& > .MuiPaper-root': {
      width: 800,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  drawerHeaderContainer: {
    height: 87,
    width: '100%',
    marginTop: theme.spacing(2.5),
    position: 'relative',
  },
  drawerHeaderTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 3),
  },
  drawerHeaderTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 32,
    color: theme.palette.grey[700],
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2.875),
    paddingLeft: theme.spacing(3),
  },
  drawerHeaderArrows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 48,
  },
  arrowBtn: {
    width: 32,
    height: 32,
    borderRadius: '50%',
  },
  arrowIcon: {
    fontSize: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  arrowIconDisabled: {
    color: theme.palette.grey['300'],
    // backgroundColor: 'transparent',
  },
  arrowIconEnabled: {
    color: theme.palette.primary.main,
  },
  drawerHeaderAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 3, 3, 3),
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1.5),
  },
  todoBtn: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  openInNewTab: {
    color: theme.palette.grey[400],
  },
  navigateNext: {
    width: 24,
    height: 24,
    color: theme.palette.primary.main,
  },
  rotateNavigateNext: {
    transform: `rotate(90deg)`,
  },
});

export const useCareGapsGroupStyles = makeStyles({
  root: {
    position: 'relative',
  },
  careGapsGroup: {
    margin: theme.spacing(0, 3.125),
  },
  groupTitle: {
    padding: theme.spacing(4, 0, 1.5, 0),
    height: 28,
    lineHeight: '28px',
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
  },
});

export const useCollapseButtonStyles = makeStyles({
  root: {
    margin: theme.spacing(0.5, 0.75),
    padding: theme.spacing(0, 1),

    '& > .MuiButton-text': {
      padding: theme.spacing(0.5, 0),
    },

    '& > .MuiButton-label': {
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    '& > .MuiButton-label > .MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  navigateNext: {
    width: 20,
    height: 20,
    color: theme.palette.grey[600],
  },
  rotateNavigateNext: {
    transform: `rotate(-90deg)`,
  },
  text: {
    fontSize: 14,
    height: 24,
    lineHeight: '24px',
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
  },
});

export const useCareGapsPanelStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 28,
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    '& > .MuiPaper-root > .Mui-expanded': {
      minHeight: 28,
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
    '& > .MuiPaper-root:before': {
      height: 0,
    },

    '& > .Mui-expanded': {
      margin: 0,
    },
    marginBottom: theme.spacing(1.5),
  },
  panel: {},
  panelContainer: {
    width: '100%',
    '& .MuiAccordion-root.Mui-expanded': {
      margin: '22 0',
    },
  },
  panelHeader: {
    padding: 0,
    borderRadius: 8,
    '&:hover': {
      background: theme.palette.grey[100],
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
    '& > .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 0',
    },
    '& .Mui-expanded': {
      margin: '0 0',
    },
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[700],
    marginLeft: 12,
  },
  countText: {
    fontSize: 14,
    lineHeight: '28px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600],
    margin: theme.spacing(0, 3.875, 0, 0),
  },
  navigateNext: {
    width: 24,
    height: 24,
    color: theme.palette.grey[600],
    transform: `rotate(-90deg)`,
  },
  rotateNavigateNext: {
    transform: `rotate(90deg)`,
  },
  panelItems: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
  },
  closedCareGapsContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 200px)', 
  },
  lastCareGapItem: {
    marginBottom: theme.spacing(15), // ~120px of extra space
  },
  expandButtonContainer: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 8,
  },
  noDataText: {
    color: '#ABABAB',
    fontSize: 20,
    lineHeight: '28px',
    marginTop: 240,
  },
  divider: {
    width: '100%',
    height: 32,
    backgroundColor: 'transparent',
  },
  select: {
    height: 40,
    width: 178,
    fontSize: 16,
    marginRight: 32,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectMenuContent: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    marginBottom: 1,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  flexContainer: {
    display: 'flex',
  },
});

export const useCareGapsPanelItemStyles = makeStyles({
  root: {
    margin: theme.spacing(1, 0, 0, 0),
    borderRadius: 8,
    border: 'none',
    '& .MuiCardContent-root': {
      padding: 8,
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 8,
    },
  },
  callAttemptContainer: {
    marginRight: 5,
    borderRadius: 6,
    background: '#EDF6FF',
    height: 24,
  },
  callAttemptColumn: {
    padding: '2px 4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
  },
  callAttemptText: {
    fontSize: 12,
    color: '#0C77D8',
    lineHeight: '20px',
    fontWeight: 600,
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
  },

  NoteOver30days: {
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  NoteOverDue: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#A02C32',
    fontWeight: 600,
  },
  NoteDueIn30days: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#984800',
    fontWeight: 600,
  },
  NoteClosedManually: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#984800',
    fontWeight: 600,
  },
  NoteClosedByPayer: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#888888',
    fontWeight: 600,
  },

  titleDateOver30days: {
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  titleDateOverDue: {
    fontSize: 12,
    lineHeight: '24px',
    color: '#A02C32',
    fontWeight: theme.typography.fontWeightBold,
  },
  titleDateDueIn30days: {
    fontSize: 12,
    lineHeight: '24px',
    color: '#984800',
    fontWeight: theme.typography.fontWeightBold,
  },
  titleDateClosedManually: {
    fontSize: 12,
    lineHeight: '24px',
    color: '#984800',
    fontWeight: theme.typography.fontWeightBold,
  },
  titleDateClosedByPayer: {
    fontSize: 12,
    lineHeight: '24px',
    color: '#888888',
    fontWeight: theme.typography.fontWeightBold,
  },
  titleOverDue: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: 'rgba(183, 55, 62, 1)',
  },
  contentPast: {
    fontSize: 12,
    fontWeight: 600,
    color: 'rgba(183, 55, 62, 1)',
  },
  titleClose: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: '#434343',
  },
  titleCloseByManually: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: '#984800',
  },
  contentClose: {
    fontSize: 12,
    fontWeight: 600,
    color: '#888888',
  },
  titleDueIn30days: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: 'rgba(152, 72, 0, 1)',
  },
  contentDue: {
    fontSize: 12,
    fontWeight: 600,
    color: 'rgba(152, 72, 0, 1)',
  },
  titleDueAfter30days: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  contentOverDue: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  contentContainer: {
    '& .MuiTooltip-tooltip': {
      opacity: 0.8,
      borderRadius: 8,
      fontSize: 12,
      fontWeight: 600,
      padding: '8px 11px 11px 12px',
    },
  },
  listItem: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
    '&:hover': {
      borderRadius: 8,
    },
  },
  selected: {
    backgroundColor: '#EDF6FF!important',
    color: '#0C77D8!important',
    borderRadius: 8,
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  careGapStatusSelect: {
    width: 'fit-content',
    height: 24,
    fontSize: 12,
    backgroundColor: '#0C77D8',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#1B8CF3',
    },
    '&:before': {
      borderWidth: 0,
      border: 0,
    },
    '&:after': {
      borderWidth: 0,
      border: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#0761B3',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 6,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      border: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px!important',
    },
    '& .MuiSelect-outlined': {
      borderRadius: 6,
    },
    '& .MuiSelect-icon': {
      color: '#FFF',
      top: 'calc(50% - 8px)',
      fontSize: 15,
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px 14px',
      paddingRight: 28,
      lineHeight: '20px',
    },
  },
  careGapStatusSelectClosed: {
    width: 'fit-content',
    height: 24,
    fontSize: 12,
    backgroundColor: '#C05314',
    '&:hover': {
      backgroundColor: '#C05314',
    },
    '&:before': {
      borderWidth: 0,
      border: 0,
    },
    '&:after': {
      borderWidth: 0,
      border: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#C05314',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 6,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      border: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px!important',
    },
    '& .MuiSelect-outlined': {
      borderRadius: 6,
    },
    '& .MuiSelect-icon': {
      color: '#FFF',
      top: 'calc(50% - 8px)',
      fontSize: 15,
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px 14px',
      paddingRight: 28,
      lineHeight: '20px',
      color: '#FFF',
    },
  },
  backBtn: {
    // width: 71,
    height: 40,
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    margin: theme.spacing(1.875, 'auto', 2, 3),

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  updateBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 89,
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0),
    marginRight: theme.spacing(2.875),
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  userTypedNotesContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
  },
});
