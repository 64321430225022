import {theme} from "../../../../../../../../../themes/theme";
import { makeStyles } from '@material-ui/core';
import {
    Typography,
} from '@material-ui/core';

export interface InsuranceListItem {
    insuranceOrder: string,
    insuranceName: string,
    payerId: string,
    subscriberId: string,
    lob: string,
}

interface InsuranceListProps {
    data: InsuranceListItem[]
}

const InsuranceList = (props: InsuranceListProps) => {
    const {data} = props
    const classes = useInsuranceListStyle();

    if (!data || data.length === 0) {
        return null;
    }
    const $title = (
        <Typography className={classes.sectionTitleText}>
            Insurance
        </Typography>
    )

    const headers = ["", 'Insurance Name', "Payer ID", "Member ID", "Insurance Type"]
    const $headers = (
        <div className={classes.row}>
            {headers.map((hd) => {
                return (
                    <div className={classes.rowItem}>
                        <Typography className={classes.itemText}>{hd}</Typography>
                    </div>
                )
            })}
        </div>
    )

    const $list = (
        <div className={classes.table}>
            {data.map((row) => {
                return (
                    <div className={classes.row}>
                        <div className={classes.rowItem}>
                            <Typography className={classes.itemText}>{row.insuranceOrder}</Typography>
                        </div>
                        <div className={classes.rowItem}>
                            <Typography className={classes.itemTextBold}>{row.insuranceName}</Typography>
                        </div>
                        <div className={classes.rowItem}>
                            <Typography className={classes.itemTextBold}>{row.payerId}</Typography>
                        </div>
                        <div className={classes.rowItem}>
                            <Typography className={classes.itemTextBold}>{row.subscriberId}</Typography>
                        </div>
                        <div className={classes.rowItem}>
                            <Typography className={classes.itemTextBold}>{row.lob}</Typography>
                        </div>
                    </div>
                )
            })}
        </div>
    )

    const $table = (
        <div>
            {$headers}
            {$list}
        </div>
    )
    return (
        <div className={classes.container}>
            {$title}
            {$table}
        </div>
    )
}

export default InsuranceList;

export const useInsuranceListStyle = makeStyles({
    container: {
        padding: theme.spacing(0, 3, 0, 3),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
    },
    sectionTitle: {
        padding: theme.spacing(4, 0, 0, 0),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sectionTitleText: {
        height: 28,
        lineHeight: '28px',
        fontSize: 20,
        fontWeight: theme.typography.fontWeightBold,
    },
    table: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        columnGap: 8,
        // justifyContent: 'space-between',
    },
    rowItem: {
        display: 'flex',
        flex: 5,
        minHeight: 40,
        alignItems: 'center',
        justifyContent: 'start',
    },
    itemTextBold: {
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[700],
    },
    itemText: {
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[500],
    },
});
