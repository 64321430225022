import React from 'react';
import { useCareGapDrawerHeaderStyles } from '../../Styles';
import { Box, Button, ButtonBase, Divider, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router';
import {
  OutComeOfCallsActionType,
  OutComeOfCallsDialog,
} from '../CareCoordination/Dialogs/OutComeOfCallsDialog';
import { GlobalContext } from '../../../../../../../../../components/GlobalContext';
import {
  useSaveCareAttemptMutation,
  useSaveManualCareStatusMutation,
} from '../../../CareGapsDrawer/index.generated';
import { useParams } from 'react-router';
import { getCallAttemptPipe } from '../../../../../../../../../sharePipe/getCallAttemptPipe';
import { CoordinationStatusChip } from '../../../../../../../../../components/CoordinationStatusChip';
import { SideBarProfileItem } from '../../../../../../../../../components/SideBarProfileItem';
import { Menu, MenuItem, MenuProps, fade, styled } from '@material-ui/core';
import { MoreHorizIcon } from '../../../../../../../../../assets/Icons/Eligibility/MoreHorizIcon';
import { AllAssignedIcon } from '../../../../../../../../../assets/Icons/PanelSummary';
import { CallAttemptComponent } from '../CallAttemptComponent';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { CareCoordinationMenu } from '../StyledCoordinationMenu';
import { ManualCareCoordinationDialog } from '../CareCoordination/Dialogs/ManualCareCoordinationDialog';
import {
  CareCoordinationOptionType,
  useCareCoordinationOption,
} from '../../../../hooks/useCareCoordinationOption';
import { EditableSnoozeDialog } from '../CareCoordination/Dialogs/EditableSnoozeDialog';
import { MixPanelEvents } from '../../../../../../../../../mixpanel/events';
import {
  PanelCoordinator,
  SiteFollowUpUser,
} from '../../../../../../../../../app.types.generated';
import {ButtonMediumPrimaryText} from "../../../../../../../../../components/Buttons";
import { theme } from '../../../../../../../../../themes/theme';
import { LabelWithCopy } from '../../../../../../../../../components/LabelWithCopy';

interface CareGapDrawerHeaderActionProps {
  showCoordinator: boolean;
  coordinator: {
    firstName: string;
    lastName: string;
    title: string;
    avatarUrl: string;
  };
  setEnableChangeCoordinatorDialog?: (input: boolean) => void;
  handleSwitchNextPatient: any;
  patientId: any;
  patientInfo: any;
  hardRefresh: any;
  disabledArrowForwardBtn: boolean;
  pid: string;
}

export const CareGapDrawerHeaderAction = (
  props: CareGapDrawerHeaderActionProps
) => {
  const {
    showCoordinator,
    coordinator,
    setEnableChangeCoordinatorDialog,
    disabledArrowForwardBtn,
    patientInfo,
  } = props;

  const {
    careCoordinationOptions,
    showCallAttemptButtonByStatus,
  } = useCareCoordinationOption(patientInfo);
  const [completionUser, setCompletionUser] = React.useState<
    SiteFollowUpUser | undefined
  >(undefined);
  const classes = useCareGapDrawerHeaderStyles();
  const { id } = useParams();
  const [inAssignedPatientTable, setInAssignPatientTable] = React.useState(
    false
  );
  const [isUnassigned, setIsUnassigned] = React.useState(false);

  const [onOpenDialog, setOnOpenDialog] = React.useState(false);
  const [actionTitle, setActionTitle] = React.useState('');
  const [selectedAction, setSelectedAction] = React.useState(
    OutComeOfCallsActionType.APPOINTMENT_BOOKED
  );
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);

  const [saveCareAttempt] = useSaveCareAttemptMutation();
  const [saveManualCareStatusMutation] = useSaveManualCareStatusMutation();

  const classesCopy = useCopyLabelStyles();
  const classesMenuItem = useMenuItemStyles();

  const setCareAttemptInput = (
    snoozeDate: any,
    wakeInDate: any,
    outReachReason: any,
    customMessage: any,
    snoozeOnly: boolean
  ): any => {
    if (snoozeOnly) {
      return {
        careSnoozedEndDate: new Date(snoozeDate),
        isCareSnoozed: 'Y',
        isSnoozedWithoutAttempt: 'Y',
        outcomeOfCall: '',
        panelId: id,
        patientId: props.patientId,
      };
    }
    switch (selectedAction) {
      case 'Unable to leave message':
        return snoozeDate == ''
          ? {
              isCareSnoozed: 'N',
              outcomeOfCall: selectedAction,
              panelId: id,
              patientId: props.patientId,
            }
          : {
              careSnoozedEndDate: new Date(snoozeDate),
              isCareSnoozed: 'Y',
              outcomeOfCall: selectedAction,
              panelId: id,
              patientId: props.patientId,
            };
      case 'Left Message':
        return snoozeDate == ''
          ? {
              isCareSnoozed: 'N',
              outcomeOfCall: selectedAction,
              panelId: id,
              patientId: props.patientId,
            }
          : {
              careSnoozedEndDate: new Date(snoozeDate),
              isCareSnoozed: 'Y',
              outcomeOfCall: selectedAction,
              panelId: id,
              patientId: props.patientId,
            };

      case 'No Response':
        return snoozeDate == ''
          ? {
              isCareSnoozed: 'N',
              outcomeOfCall: selectedAction,
              panelId: id,
              patientId: props.patientId,
            }
          : {
              careSnoozedEndDate: new Date(snoozeDate),
              isCareSnoozed: 'Y',
              outcomeOfCall: selectedAction,
              panelId: id,
              patientId: props.patientId,
            };

      case 'Appointment Booked':
        return {
          outcomeOfCall: selectedAction,
          panelId: id,
          patientId: props.patientId,
        };
      case 'Plan to Walk In':
        return {
          planToWalkInDate: new Date(wakeInDate),
          outcomeOfCall: selectedAction,
          panelId: id,
          patientId: props.patientId,
        };
      case 'Patient Will Call Back':
        return snoozeDate == ''
          ? {
              isCareSnoozed: 'N',
              outcomeOfCall: selectedAction,
              panelId: id,
              patientId: props.patientId,
            }
          : {
              careSnoozedEndDate: new Date(snoozeDate),
              isCareSnoozed: 'Y',
              outcomeOfCall: selectedAction,
              panelId: id,
              patientId: props.patientId,
            };
      case 'Close Outreach':
        return {
          outcomeOfCall: selectedAction,
          panelId: id,
          patientId: props.patientId,
          closeOutreachReason:
            outReachReason == 'Other' ? customMessage : outReachReason,
        };
      case 'Letter Sent':
        return {
          outcomeOfCall: selectedAction,
          panelId: id,
          patientId: props.patientId,
          closeOutreachReason:
            outReachReason == 'Other' ? customMessage : outReachReason,
        };
      default:
        return {
          outcomeOfCall: selectedAction,
          panelId: id,
          patientId: props.patientId,
        };
    }
  };

  const onSnooze = async (snoozeDate: any) => {
    const unSnoozeInput = {
      panelId: id,
      patientId: props.patientId,
      manualCareStatus: 'Snooze',
      isCareSnoozed: 'N',
    };

    const snoozeInput = {
      panelId: id,
      patientId: props.patientId,
      manualCareStatus: 'Snooze',
      careSnoozedEndDate: new Date(snoozeDate),
    };

    await saveManualCareStatusMutation({
      variables: {
        input: snoozeDate == '' ? unSnoozeInput : snoozeInput,
      },
    });

    setToastMessage({
      isOpen: true,
      severity: 'success',
      snackbarMsg: 'Patient Status Updated.',
    });
    setIsOpenToast(true);
    props.hardRefresh();
  };

  const onSubmit = async (
    next: any,
    snoozeDate: any,
    wakeInDate: any,
    outReachReason: any,
    customMessage: any
  ) => {
    await saveCareAttempt({
      variables: {
        input: setCareAttemptInput(
          snoozeDate,
          wakeInDate,
          outReachReason,
          customMessage,
          false
        ),
      },
    });
    MixPanelEvents.track(
      MixPanelEvents.userActivityAndJourneyMapping.captureCallOutCome.name
    );
    setOnOpenDialog(false);

    setToastMessage({
      isOpen: true,
      severity: 'success',
      snackbarMsg: 'Patient Status Updated.',
    });
    setIsOpenToast(true);
    if (next) {
      props.handleSwitchNextPatient();
    }
    props.hardRefresh();
  };

  const onSubmitManualCareCoordination = async (input?: any) => {
    if (input.notes !== undefined) {
      // site follow up => input {notes: '', designee: {id: ''}}
      // completed site follow up => input {notes: ''}
      const currentPanelCoordinator = patientInfo.panels?.find(
        (panel: PanelCoordinator) => panel.panelId === id
      );
      const isSiteFollowUpStatusNow =
        patientInfo?.careCoordinationStatus === 'Site Follow Up';
      const userID = isSiteFollowUpStatusNow
        ? completionUser?.id.replace('user#', '')
        : input?.designee?.id?.replace('user#', '');
      const isExitSiteFollowUp = isSiteFollowUpStatusNow ? 'Y' : 'N';
      // The status must be 'Site Follow Up' if complete the site follow up.
      const careStatus = isExitSiteFollowUp
        ? 'Site Follow Up'
        : manualCareCoordinationStatus.selectedOptionValue;
      const param = {
        manualCareStatus: careStatus,
        panelId: id,
        patientId: props.patientId,
        siteFollowUpNotes: input.notes,
        siteFollowUpToUserId: userID,
        exitSiteFollowUp: isExitSiteFollowUp,
        siteFollowUpFromUserId: currentPanelCoordinator.coordinatorId,
      };
      await saveManualCareStatusMutation({
        variables: {
          input: param,
        },
      });
    } else {
      // other manual care coordination actions
      // input => SyntheticBaseEvent
      await saveManualCareStatusMutation({
        variables: {
          input: {
            manualCareStatus: manualCareCoordinationStatus.selectedOptionValue,
            panelId: id,
            patientId: props.patientId,
          },
        },
      });
    }
    setToastMessage({
      isOpen: true,
      severity: 'success',
      snackbarMsg: 'Patient Status Updated.',
    });
    setIsOpenToast(true);
    props.hardRefresh();
  };

  React.useEffect(() => {
    switch (props.patientInfo?.careAttemptCount) {
      case null:
        setActionTitle('First Attempt');
        break;
      default:
        setActionTitle(
          getCallAttemptPipe(props.patientInfo?.careAttemptCount) + ' Attempt'
        );
        break;
    }
  }, [props.patientInfo]);

  const location = useLocation();
  React.useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts[pathParts.length - 1] === 'assigned') {
      setInAssignPatientTable(true);
    } else {
      setInAssignPatientTable(false);
    }

    if (pathParts[pathParts.length - 1] === 'unassigned') {
      setIsUnassigned(true);
    } else {
      setIsUnassigned(false);
    }
  }, [location.pathname]);



  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOption = (event: any) => {
    setAnchorEl(null);
    if (event) {
      setEnableChangeCoordinatorDialog &&
        setEnableChangeCoordinatorDialog(true);
    }
  };

  const [
    manualCareCoordinationStatus,
    setManualCareCoordinationStatus,
  ] = React.useState({
    open: false,
    selectedOptionValue: '',
    submitClicked: false,
  });

  const refEle = React.useRef(null);
  const onChangeCC = () => {
    setAnchorElCC((refEle.current as unknown) as HTMLElement);
  };
  const [anchorElCC, setAnchorElCC] = React.useState<null | HTMLElement>(null);
  const openMenuCC = Boolean(anchorElCC);

  const handleCloseCC = () => {
    setAnchorElCC(null);
  };
  const handleOptionCC = (event: any) => {
    setAnchorElCC(null);
    setManualCareCoordinationStatus({
      open: true,
      selectedOptionValue: event.target.innerText,
      submitClicked: false,
    });
  };

  const handleReopenOptionCC = () => {
    setAnchorElCC(null);
    setManualCareCoordinationStatus({
      open: true,
      selectedOptionValue: 'Reopen',
      submitClicked: false,
    });
  }
  const handleCloseManualCareCoordinationDialog = () => {
    setManualCareCoordinationStatus({
      open: false,
      selectedOptionValue: '',
      submitClicked: false,
    });
  };
  const handleConfirmManualCareCoordinationDialog = async (input?: any) => {
    setManualCareCoordinationStatus((prev) => ({
      ...prev,
      open: false,
      submitClicked: true,
    }));
    await onSubmitManualCareCoordination(input);
  };
  const getCareCoordinationChipMenu = () => {
    if (!showCallAttemptButtonByStatus || !inAssignedPatientTable) {
      if (patientInfo?.careCoordinationStatus === 'Outreach Closed') {
        return (
            <div className={classes.drawerHeaderActionContainer}>
              <Button
                  variant="contained"
                  disabled={true}
                  disableElevation
                  disableRipple
                  className={classes.outReachCloseButton}
              >
                {'Outreach Closed'}
              </Button>

              <ButtonMediumPrimaryText
                  onClick={handleReopenOptionCC}
              >
                <span className={classes.attemptBtnText}>{"Reopen"}</span>
              </ButtonMediumPrimaryText>
            </div>
        )
      }
      // only show the coordination status if the patient is outreach closed or deceased or not in assigned patient page
      return (
        <CoordinationStatusChip
          patientInfo={props.patientInfo}
          location="PatientFaceSheet"
        />
      );
    } else {
      return (
          <CareCoordinationChipMenu
              patientInfo={props.patientInfo}
              refEle={refEle}
              onChange={onChangeCC}
              openMenu={openMenuCC}
              anchorEl={anchorElCC}
              handleClose={handleCloseCC}
              handleOption={handleOptionCC}
              options={careCoordinationOptions}
          />
      )
    }
  };
  const handleSubmitSnooze = (snoozeDate: string) => {
    setManualCareCoordinationStatus((prev) => ({
      ...prev,
      open: false,
      submitClicked: true,
    }));
    onSnooze(snoozeDate);
  };

  const getSnoozeDialogTitle = () => {
    switch (manualCareCoordinationStatus.selectedOptionValue) {
      case 'Snooze':
        return 'Snooze Patient';
      case 'Edit Snooze':
        return 'Edit Snooze';
      default:
        return 'Snooze Patient';
    }
  };
  const getDialogBySelectedOption = () => {
    if (manualCareCoordinationStatus.open) {
      if (manualCareCoordinationStatus.selectedOptionValue.includes('Snooze')) {
        return (
          <EditableSnoozeDialog
            dialogTitle={getSnoozeDialogTitle()}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            manualCareCoordinationStatus={manualCareCoordinationStatus}
            handleClose={handleCloseManualCareCoordinationDialog}
            onConfirm={handleSubmitSnooze}
          />
        );
      } else {
        return (
          <ManualCareCoordinationDialog
            selectedOption={manualCareCoordinationStatus.selectedOptionValue}
            handleClose={handleCloseManualCareCoordinationDialog}
            onConfirm={handleConfirmManualCareCoordinationDialog}
            mpatientId={props.patientId}
            setCompletionUser={setCompletionUser}
            completionUser={completionUser}
          />
        );
      }
    }
  };

  return (
    <div className={classes.drawerHeaderAction}>
      <div className={classes.actionButtons}>
        {/* Attempt button */}
        {/* hide the call attempt button and the drop menu if the patient is outreach closed or deceased */}
        {showCallAttemptButtonByStatus && (
          <CallAttemptComponent
            inAssignedPatientTable={inAssignedPatientTable}
            showCoordinator={showCoordinator}
            patientInfo={patientInfo}
            actionTitle={actionTitle}
            onOpenDialog={onOpenDialog}
            setOnOpenDialog={setOnOpenDialog}
          />
        )}
        {onOpenDialog && (
          <OutComeOfCallsDialog
            openDialog={onOpenDialog}
            setOpenDialog={setOnOpenDialog}
            dialogTitle={actionTitle}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            onSubmit={onSubmit}
            disabledArrowForwardBtn={disabledArrowForwardBtn}
          />
        )}
        {/* drop down */}
        <div className={classes.dropDownContainer}>
          {getCareCoordinationChipMenu()}
        </div>
      </div>

      {(
        <div className={classes.meAndOpenNewContainer}>
          {/* coordinator name initials Icon button */}
          {
            <>
             {inAssignedPatientTable ? (
               <SideBarProfileItem
               firstName={coordinator.firstName}
               lastName={coordinator.lastName}
               title={coordinator.title}
               avatarUrl={coordinator.avatarUrl}
             />
             ) : null}
              <>
                <Button
                  className={classes.editButton}
                  id={`more-option-button-pfs`}
                  aria-haspopup="true"
                  variant="contained"
                  disableElevation
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <MoreHorizIcon className={classes.moreHorizonIcon} />
                </Button>

                <StyledMenu
                  id={`change-coordinator-pfs`}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  className={classes.menu}
                  PaperProps={{
                    style: {
                      width: 'auto',
                      minWidth: 'fit-content'
                    }
                  }}
                >
                  {showCoordinator && (
                    <MenuItem onClick={handleOption} disableRipple>
                    <span>Change Coordinator</span>
                    <AllAssignedIcon
                      className={classes.iconStyleMenu}
                    />
                  </MenuItem>
                  )}

                  <Divider />

                  <div className={classesMenuItem.menuItem}>
                      <LabelWithCopy
                        label={`iPH PID: ${props.pid}`}
                        customClasses={classesCopy}
                        optionalCopyText={props.pid}
                      />
                  </div>
                </StyledMenu>
              </>
            </>
          }
        </div>
      )}

      {getDialogBySelectedOption()}
    </div>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    PaperProps={{
      style: {
        transform: 'translateY(9px)',
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    width: '220px',
    color: theme.palette.text.secondary,
    boxShadow:
      '0px 0px 3px 0px rgba(0, 0, 0, 0.16), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
    '& .MuiMenu-list': {
      padding: '0px 0px',
    },
    '& .MuiMenuItem-root': {
      padding: '8px 16px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.hint,
        marginTop: theme.spacing(0.1875),
        marginRight: theme.spacing(0.3125),
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.3125),
      },
      '&:active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    '& .MuiListItem-root span': {
      display: 'inline-block',
      height: '24px',
      color: 'theme.palette.secondary.dark',
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      marginRight: theme.spacing(2),
    },
  },
}));

type CareCoordinationChipMenuProps = {
  patientInfo: any;
  refEle: any;
  onChange: () => void;
  openMenu: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  handleOption: (input: any) => void;
  options: CareCoordinationOptionType[];
};

const CareCoordinationChipMenu: React.FC<CareCoordinationChipMenuProps> = ({
  patientInfo,
  refEle,
  onChange,
  openMenu,
  anchorEl,
  handleClose,
  handleOption,
  options,
}) => {
  return (
    <>
      {/* @ts-ignore  */}
      <Box onClick={onChange} component={ButtonBase} ref={refEle}>
        <CoordinationStatusChip
          patientInfo={patientInfo}
          location="PatientFaceSheet"
          endIcon={openMenu ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          onChangeCC={onChange}
        />
      </Box>

      <CareCoordinationMenu
        anchorEl={anchorEl}
        openMenu={openMenu}
        handleClose={handleClose}
        handleOption={handleOption}
        CareCoordinationOptions={options}
      />
    </>
  );
};

export const useCopyLabelStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start', // Changed from space-between to avoid extra spacing
    alignItems: 'center',
    width: 'fit-content',
    height: 24,
    padding: 0,
    marginTop: 4,
    columnGap: 8,
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
  },
  label: {
    '& span': {
      color: theme.palette.grey[500],
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  icon: {
    '& svg': {
      color: '#26ACE2',
      fontSize: 16,
      marginTop: 2,
    },
  },
});


export const useMenuItemStyles = makeStyles({
  menuItem: {
    padding: '8px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: theme.spacing(1),
  },
});
