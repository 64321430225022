import {
  Button,
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import React from 'react';
import {PatientFilterComponentIDs} from "../../../../../../../enum/store";
import {usePatientFilterStore} from "../../../../../../../store/features";
import InsuranceList from "./components/Insurance";
import { usePatientInfoViewStyles } from './Styles';
import { useParams } from 'react-router';
import { OpenInNewWindowIcon } from '../../../../../../../assets/Icons/CareGaps';
import { ProfileItem } from '../../../../../../../components/ProfileItem';
import { theme } from '../../../../../../../themes/theme';
import { NotRegisterIcon } from '../../../../../../../assets/Icons/FileUpload/NotRegisterIcon';
import { PatientFaceSheet } from '../CareGapsDrawer/Container';
import {
  Appointment,
  ClinicPatientInfo,
  PanelCoordinator,
  PanelPatient,
} from '../../../../../../../app.types.generated';
import { dateFormatPipe } from '../../../../../../../sharePipe/dateFormatPipe';
import { AddNewNoteButton } from './components/AddNewNoteButton';
import { EditNodesForm, EditNotes } from './components/EditNotes';
import { GlobalContext } from '../../../../../../../components/GlobalContext';
import { useSaveCareNotesMutation } from '../CareGapsDrawer/index.generated';
import { EmptyPlaceHolder } from '../../../../../../../components/EmptyPlaceHolder';
import { ArrowForwardIos as ArrowForwardIcon } from '@material-ui/icons';
import { CheckIsCoordinator } from '../../../../../../../components/CheckIsAdmin';
import { KeyPatientsType } from '../../../../../../Panels/ViewPanels/PanelPatients/hooks/useKeyPatients';
import { PhoneInfoDetail } from './components/PhoneInfoDetail';
import CheckEligibility from './components/CheckEligibility';
import { PopoverCell } from '../CareGapPatientTable/components/DivisionCell';
import { MixPanelEvents } from '../../../../../../../mixpanel/events';
import { DeListPatientDialog } from '../CareGapDrawerHeader/components/CareCoordination/Dialogs/DeListPatientDialog';
import { useDeListPatient } from './hooks/useDeListPatient';
import moment from 'moment';
import { CareGapContext } from '../../context';
import { ServiceUnavailableAlertBar } from '../../../../../../../components/ServiceUnavailableAlertBar';
import { PanelSummaryContext } from '../../../../../../Panels/ViewPanels/PanelSummary/components/PanelSummaryContext/PanelSummaryContext';
import { useFeature } from '../../../../../../../components/FeatureFlag';

export type DeListPatientDialogStateType = {
  open: boolean;
  panelName: string;
  panelId: string;
  onConfirmClicked: boolean;
};

const CLINIC_ID_PATIENT_ID_MAX_LIMIT = 3;

interface SectionField {
  id: string;
  label: string;
}

const generalInfoSection: SectionField[] = [
  {
    id: 'priority',
    label: 'Priority',
  },
  {
    id: 'language',
    label: 'Language(s)',
  },
  {
    id: 'divisions',
    label: 'Division(s)',
  },
  {
    id: 'subscriberIDs',
    label: 'Subscriber ID',
  },
  {
    id: 'lineOfBusinessInfo',
    label: 'LOB',
  },
];
const contactSection: SectionField[] = [
  {
    id: 'homePhone',
    label: 'Phone(Home)',
  },
  {
    id: 'mobilePhone',
    label: 'Phone(Mobile)',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'email',
    label: 'Email',
  },
];

type GroupedFutureAppointment = {
  regularAppointment: Appointment[];
  specialtyAppointment: Appointment[];
};

interface InfoListProps {
  patientData: any;
  classes: ClassNameMap;
  sectionName: string;
  sectionFields?: SectionField[];
  setClickedFamilyRows?: (input: boolean) => void;
  originalPatientId?: string;
  hardRefresh: () => void;
  setKeyPatients?: React.Dispatch<React.SetStateAction<KeyPatientsType>>;
  showLockIcon?: boolean;
  setDeListPatientDialogState?: (input: DeListPatientDialogStateType) => void;
}

const InfoList = (props: InfoListProps) => {
  const {
    patientData,
    classes,
    sectionName,
    sectionFields,
    setClickedFamilyRows,
    originalPatientId,
    hardRefresh,
    setKeyPatients,
    showLockIcon,
  } = props;
  const [
    groupedFutureAppointments,
    setGroupedFutureAppointments,
  ] = React.useState<GroupedFutureAppointment | null>(null);
  const { setToastMessage, setIsOpenToast, loggedInUser } = React.useContext(
    GlobalContext
  );
  const [addNewBtnClicked, setAddNewBtnClicked] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { id } = useParams();

  const { activePatientFilter } = React.useContext(
      CareGapContext
  );

  const closeEditNotes = () => {
    setAddNewBtnClicked(false);
  };

  const hasPriority = useFeature('priority');

  const LatestNoteElement = React.useMemo(() => {
    if (patientData.notes && patientData.notes.length > 0) {
      // by default the 1st one is the latest notes
      const latestOne = patientData.notes[0];
      return (
        <div className={classes.notesRowContainer}>
          <div className={classes.notePcpAndCreatedAt}>
            <Typography variant="body1" className={classes.noteCreatedAt}>
              {dateFormatPipe(latestOne.createdAt, '', false)}
            </Typography>
            <ProfileItem
              firstName={latestOne.pcp.firstName}
              lastName={latestOne.pcp.lastName}
              avatarBgColor={theme.palette.avatars.yellow}
            />
          </div>
          <Typography variant="body1" className={classes.noteContent}>
            {latestOne.content}
          </Typography>
        </div>
      );
    } else {
      return <></>;
    }
  }, [patientData.notes]);

  const [saveCareNotesMutation] = useSaveCareNotesMutation();
  const submitNotes = async (input: EditNodesForm) => {
    // call api to submit notes
    closeEditNotes();
    const { data, errors } = await saveCareNotesMutation({
      variables: {
        input: {
          createdAt: new Date(),
          patientId: patientData.id,
          notes: input.notes.trim(),
          panelId: id,
        },
      },
    });
    if (errors || !data || data?.saveCareNotes.isSuccess === false) {
      setToastMessage({
        snackbarMsg: `Failed to add notes, please try again.`,
        severity: 'error',
        isOpen: true,
      });
      setIsOpenToast(true);
    }
    MixPanelEvents.track(
      MixPanelEvents.userActivityAndJourneyMapping.addNotesInPatientFaceSheet
        .name
    );
    if (data?.saveCareNotes.isSuccess) {
      setToastMessage({
        snackbarMsg: `New notes added`,
        severity: 'success',
        isOpen: true,
      });
      setIsOpenToast(true);
    }
    hardRefresh();
  };

  const getPropertyContentNode = (item: any) => {
    if (patientData.hasOwnProperty(item.id)) {
      const result = patientData[item.id];

      if (!result) return;

      switch (item.id) {
        case 'language':
          return (
            <Typography className={classes.itemTextRightExceptPanels}>
              {result}
            </Typography>
          );
        case 'divisions':
          // divisions{id, name} => divisionNames[]
            const isNoneDivisionsClinic = patientData.clinicId !== '9000' && patientData.clinicId !== '9002'
            const divisions = isNoneDivisionsClinic ? [""] : result.map((item: any) => item.name)
          return (
            <PopoverCell
              itemTitle="Division(s)"
              itemStrings={divisions}
            />
          );
        case 'subscriberIDs':
          return <PopoverCell itemTitle="Subscriber ID" itemStrings={result} />;
        case 'lineOfBusinessInfo':
          return (
            <Typography className={classes.itemTextRightExceptPanels}>
              {result.desc}
            </Typography>
          );
        case 'priority':
          return (
              <Chip
                  className={classes.chip}
                  label={result}
              />
          )
        default:
          return (
            <>
              <div className={classes.flexCenterEnd5}>
                {patientData.hasOwnProperty(item.id) &&
                item.id.toLowerCase().includes('phone') ? (
                  <PhoneInfoDetail
                    showLockIcon={showLockIcon!}
                    patientData={patientData}
                    itemId={item.id}
                    result={result}
                  />
                ) : (
                  <Typography className={classes.itemTextRightExceptPanels}>
                    {patientData.hasOwnProperty(item.id) ? result : '-'}
                  </Typography>
                )}
              </div>
            </>
          );
      }
    }
  };

  const showTextOrIcon = (panelId: string) => {
    if (panelId === id) {
      return (
        <ListItemText
          primary="Current Panel"
          className={classes.itemTextRight}
        />
      );
    } else {
      return (
        <div className={classes.flexCenterEnd5}>
          {CheckIsCoordinator(loggedInUser) ? (
            <div></div>
          ) : (
            <StyledTooltip
              title={<span>{['Open in new tab']}</span>}
              placement="bottom-start"
            >
              <span
                className={classes.openInNewTabSpan}
                onClick={() =>
                  window.open(`/panels/${panelId}/summary`, '_blank')
                }
              >
                <OpenInNewWindowIcon className={classes.openInNewTab} />
              </span>
            </StyledTooltip>
          )}
        </div>
      );
    }
  };

  const displayDatetimeIn2Lines = (formattedDatetimeString: string) => {
    // formattedDatetime: November 30, 2022 11:59 AM
    // backend update the date format to: March 24 2024 at 10:15 AM
    const formattedDate = moment(
      formattedDatetimeString,
      'MMMM D YYYY [at] h:mm A'
    ).format('MMMM D, YYYY h:mm A');
    let longMonthDate = formattedDate.split(',')[0]; // November 30
    let [year, hourMinute, ampm] = formattedDate
      .split(',')[1]
      .trim()
      .split(' ');

    return {
      date: `${longMonthDate}, ${year}`,
      time: `${hourMinute} ${ampm}`,
    };
  };

  const getOpenCareGapChipEle = (item: any) => {
    // measureCount === 0 => No Care Gaps , measureCount > 0 && caregapCount === 0 => All Gaps Closed
    let isNoCareGaps = item.measureCount === 0;
    let isAllGapsClosed = item.measureCount > 0 && item.caregapCount === 0;

    let labelText =
      (isNoCareGaps && 'No Care Gaps') ||
      (isAllGapsClosed && 'All Gaps Closed') ||
      `${item.caregapCount} Open Gaps`;

    let iconEle =
      isNoCareGaps || isAllGapsClosed ? undefined : (
        <NotRegisterIcon className={classes.warningIcon} />
      );
    let className =
      isNoCareGaps || isAllGapsClosed ? classes.closedGap : classes.openGap;

    return <Chip icon={iconEle} label={labelText} className={className} />;
  };

  const getClinicIDAndPatientID = (patients: ClinicPatientInfo[]) => {
    const _clinicIDAndPatientIDRows = (rows: ClinicPatientInfo[]) => {
      return rows.map((item, idx) => {
        return (
          <ListItem
            divider
            key={`${item.clinicId}-${item.patientId}-${idx}`}
            className={classes.itemRowLow}
          >
            <ListItemText
              primary={item.clinicId}
              className={classes.itemTextLeft}
            />
            <ListItemText
              primary={item.patientId}
              className={classes.itemTextMiddle}
            />

            <ListItemText
              primary={`Last DOS: ${item.lastDos}`}
              className={classes.itemTextRight2}
            />
          </ListItem>
        );
      });
    };

    const moreThanLimit = patients.length > CLINIC_ID_PATIENT_ID_MAX_LIMIT;
    const firstLimitItems = patients.slice(0, CLINIC_ID_PATIENT_ID_MAX_LIMIT);
    const otherItems = patients.slice(CLINIC_ID_PATIENT_ID_MAX_LIMIT);

    if (moreThanLimit) {
      return (
        <>
          {_clinicIDAndPatientIDRows(firstLimitItems)}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {_clinicIDAndPatientIDRows(otherItems)}
          </Collapse>
          <ListItem
            key={'show_more'}
            button
            onClick={handleExpandClick}
            className={classes.showMore}
          >
            <ArrowForwardIcon
              className={
                expanded
                  ? classes.showMoreIcon + ' ' + classes.rotated
                  : classes.showMoreIcon + ' ' + classes.notRotated
              }
            />
          </ListItem>
        </>
      );
    } else {
      return _clinicIDAndPatientIDRows(firstLimitItems);
    }
  };

  const getAppointmentItem = (appointments: Appointment[]) => {
    return appointments.map((item: Appointment) => {
      const { date, time } = displayDatetimeIn2Lines(item.date);

      return (
        <ListItem
          key={item.id + '-FutureAppointment'}
          className={classes.itemRowContained}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            //
          }}
        >
          <div className={classes.rowNonText}>
            <div className={classes.apptAndPcpContainer}>
              <div className={classes.appointmentDateTime}>
                <Typography className={classes.appointmentTime}>
                  {time}
                </Typography>
                <Typography className={classes.appointmentDate}>
                  {date}
                </Typography>
              </div>
              {item.pcp ? (
                <ProfileItem
                  firstName={item.pcp.firstName}
                  lastName={item.pcp.lastName}
                />
              ) : (
                <EmptyPlaceHolder text={'No Provider'} />
              )}
            </div>
            <div className={classes.newApptInClinicContainer}>
              {item.location ? (
                <Typography
                  variant="body1"
                  className={classes.appointmentLocationText}
                >
                  {item.location}
                </Typography>
              ) : (
                <div
                  style={{
                    marginRight: theme.spacing(11),
                    whiteSpace: 'nowrap',
                  }}
                >
                  <EmptyPlaceHolder text={'No Location'} />
                </div>
              )}
            </div>
          </div>
        </ListItem>
      );
    });
  };

  React.useEffect(() => {
    const tmp: GroupedFutureAppointment = {
      regularAppointment: [],
      specialtyAppointment: [],
    };
    if (
      patientData.futureAppointment &&
      patientData.futureAppointment.length > 0
    ) {
      patientData.futureAppointment.forEach((item: Appointment) => {
        if (item.isSpecialty) {
          tmp.specialtyAppointment.push(item);
        } else {
          tmp.regularAppointment.push(item);
        }
      });
      setGroupedFutureAppointments(tmp);
    }
  }, [patientData.futureAppointment]);

  const deListHandler = async (
    event: React.MouseEvent<HTMLElement>,
    item: any
  ) => {
    event.stopPropagation();
    props.setDeListPatientDialogState &&
      props.setDeListPatientDialogState({
        open: true,
        panelName: item.panelName,
        panelId: item.panelId,
        onConfirmClicked: false,
      });
  };

  const switcher = () => {
    switch (sectionName) {
      case 'General Detail':
      case 'Contact':
        return (
          sectionFields &&
          sectionFields.map((item) => {
            if (item.id === 'priority' && !hasPriority) {
              return null;
            }
            return (
              <>
                {getPropertyContentNode(item) ? (
                  <ListItem
                    divider
                    key={item.id + '-Contact'}
                    className={classes.itemRow}
                  >
                    <ListItemText
                      primary={item.label}
                      className={classes.itemTextLeft}
                    />

                    <ListItemText
                      primary={getPropertyContentNode(item)}
                      className={classes.itemTextRight}
                    />
                  </ListItem>
                ) : null}
              </>
            );
          })
        );
      case 'Clinic ID and Patient ID':
        return patientData.patients && patientData.patients.length > 0 ? (
          <div className={classes.clinicIDsAndPatientIDs}>
            {getClinicIDAndPatientID(patientData.patients)}
          </div>
        ) : null;
      case 'Future Appointment':
        return (
          <div className={classes.futureAppointmentContainer}>
            <Typography
              variant="body1"
              className={classes.futureAppointmentSubscript}
            >
              Last Day of Service:{' '}
              {patientData.lastDos ? patientData.lastDos : 'No DOS'}
              {patientData.lastDosPcpName &&
                ` by ${patientData.lastDosPcpName}`}
            </Typography>

            {groupedFutureAppointments &&
            groupedFutureAppointments.regularAppointment.length > 0
              ? getAppointmentItem(groupedFutureAppointments.regularAppointment)
              : null}

            {groupedFutureAppointments &&
            groupedFutureAppointments.specialtyAppointment.length > 0 ? (
              <>
                <Typography className={classes.futureAppointmentSubtitle}>
                  Other Appointments
                </Typography>
                {getAppointmentItem(
                  groupedFutureAppointments.specialtyAppointment
                )}
              </>
            ) : null}
          </div>
        );
      case 'Family Members':
        return patientData.familyMembers.map((item: any) => {
          return (
            <ListItem
              divider
              key={item.patientId + '-FamilyMembers'}
              button
              className={classes.itemRow}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setClickedFamilyRows && setClickedFamilyRows(true);
                setKeyPatients &&
                  setKeyPatients((oldValue) => {
                    return {
                      ori:
                        originalPatientId === '' ? patientData : oldValue.ori,
                      pre: oldValue.cur,
                      cur: {
                        id: item.patientId,
                        caregapCount: item.caregapCount,
                      } as PanelPatient,
                    };
                  });
              }}
            >
              <ListItemText
                primary={item.fullName}
                className={classes.familyMembersItemText}
              />
              {getOpenCareGapChipEle(item)}
            </ListItem>
          );
        });
      case 'Panels':
        return patientData.panels
          .filter((item: PanelCoordinator) => item.panelId === id)
          .concat(
            patientData.panels.filter(
              (item: PanelCoordinator) => item.panelId !== id
            )
          )
          .map((item: any) => {
            return (
              <ListItem
                key={item.panelId}
                className={classes.itemRowContained}
                style={{
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                }}
              >
                <ListItemText
                  primary={item.panelName}
                  className={classes.itemRowContainedTextLeft}
                />
                <div className={classes.itemRightButtonAndIconContainer}>
                  <Button
                    variant="outlined"
                    className={classes.deListButton}
                    onClick={(event) => deListHandler(event, item)}
                  >
                    De-List Patient
                  </Button>
                  <div style={{ width: 94, height: 'fit-content' }}>
                    {showTextOrIcon(item.panelId)}
                  </div>
                </div>
              </ListItem>
            );
          });
      case 'Notes':
        return (
          <>
            {!addNewBtnClicked ? (
              LatestNoteElement
            ) : (
              <EditNotes
                closeEditNotes={closeEditNotes}
                submitNotes={submitNotes}
              />
            )}
          </>
        );
      default:
        break;
    }
  };

  const getSectionName = () => {
    if (sectionName === 'Future Appointment') {
      if (
        !patientData.futureAppointment ||
        patientData.futureAppointment.length === 0 ||
        groupedFutureAppointments?.regularAppointment.length === 0
      ) {
        return 'No ' + sectionName;
      }
    }

    return sectionName;
  };

  return (
    <div className={classes.infoSection}>
      <Typography variant="h6" className={classes.sectionTitle}>
        <Typography className={classes.sectionTitleText}>
          {getSectionName()}
        </Typography>
        {getSectionName() === 'Notes' && !addNewBtnClicked && (
          <AddNewNoteButton
            handleClick={() => setAddNewBtnClicked((prev) => !prev)}
          />
        )}
      </Typography>

      <List component="nav" aria-label={getSectionName()}>
        {switcher()}
      </List>
    </div>
  );
};

interface PatientInfoViewProps {
  patientInfo: PatientFaceSheet;
  setClickedFamilyRows: (input: boolean) => void;
  originalPatientId: string;
  hardRefresh: () => void;
  setKeyPatients: React.Dispatch<React.SetStateAction<KeyPatientsType>>;
  showLockIcon: boolean;
  handleSwitchNextPatient: () => void;
  disabledArrowForwardBtn: boolean;
  drawerHandler: (
    event: React.KeyboardEvent | React.MouseEvent,
    patient?: PanelPatient
  ) => void;
}

export const PatientInfoView = (props: PatientInfoViewProps) => {
  const {
    patientInfo,
    setClickedFamilyRows,
    originalPatientId,
    hardRefresh,
    setKeyPatients,
    showLockIcon,
    handleSwitchNextPatient,
    disabledArrowForwardBtn,
    drawerHandler,
  } = props;
  const { setDeListPatientState } = React.useContext(CareGapContext);
  const classes = usePatientInfoViewStyles();

  const [dismissed, setDismissed] = React.useState(false);

  const checkSectionIsEmpty = (sectionFields: SectionField[]) => {
    const tmp = sectionFields?.map((item: SectionField) => {
      if (patientInfo.hasOwnProperty(item.id)) {
        const result = patientInfo[item.id as keyof PatientFaceSheet];
        if (!result) return true; // empty
        return false;
      }
    });

    return tmp.every((item) => item);
  };

  const { id: currentPanelId } = useParams();
  const { delistPatientFromPanel } = useDeListPatient();

  const { setNeedToRefetch } = React.useContext(PanelSummaryContext);

  const [
    deListPatientDialogState,
    setDeListPatientDialogState,
  ] = React.useState<DeListPatientDialogStateType>({
    open: false,
    panelName: '',
    panelId: '',
  } as DeListPatientDialogStateType);
  const onDeListPatientDialogClose = (event: any) => {
    setDeListPatientDialogState({
      open: false,
      panelName: '',
      panelId: '',
      onConfirmClicked: false,
    });
  };
  const onDeListPatientDialogConfirm = async (event: any) => {
    setDeListPatientDialogState({
      open: false,
      panelName: '',
      panelId: '',
      onConfirmClicked: true,
    });

    await delistPatientFromPanel(
      patientInfo.id,
      deListPatientDialogState.panelId
    );

    if (currentPanelId === deListPatientDialogState.panelId) {
      if (!disabledArrowForwardBtn) {
        handleSwitchNextPatient();
        // fetch the backend patient table data again
        // tmpDeListPatientState.patientDeListedFromCurrentPanel = true;
        setDeListPatientState({
          patientsHaveBeenDeListedFromCurrentPanel: true,
          patientDeListedFromCurrentPanel: true,
        });
      } else {
        // if there is no next patient, then close the patient face sheet
        drawerHandler(event);
      }
      // background refetch the panel data as current patient has been de-listed from the panel
      setNeedToRefetch(true);
    } else {
      // stay and refetch the current patient data
      hardRefresh();
    }
  };
  const getDeListDialogContent = () => {
    return (
      <Typography className={classes.dialogContentMain}>
        Are you sure you want to remove{' '}
        <span className={classes.dialogContentBold}>
          {patientInfo.firstName} {patientInfo.lastName}
        </span>{' '}
        from{' '}
        <span className={classes.dialogContentBold}>
          {deListPatientDialogState.panelName}
        </span>
        ? Patient will be move to De-listed tab and no longer assigned to a
        coordinator.
      </Typography>
    );
  };

  const hasFeature = useFeature('division');
  let filteredGeneralInfoSection = React.useMemo(() => {
    if (hasFeature) {
      return generalInfoSection;
    } else {
      return generalInfoSection.filter((item) => item.id !== 'divisions');
    }

  }, [hasFeature]);

  const hasEligibilityCheckButtonFeature = useFeature('eligibilityCheckButton');
  const hasEnableCopyDemographics = useFeature('copyDemographics');
  return (
    <div className={classes.root}>
      {/* removed the alert message and disabled the button as of ticket: VBC-3109 */}
      {/*
      {hasEligibilityCheckButtonFeature && !dismissed && (
        <ServiceUnavailableAlertBar
          serviceName="Eligibility Service"
          onCloseHandler={() => {
            setDismissed(true);
          }}
        />
      )}
       */}
      {hasEligibilityCheckButtonFeature && (
        <CheckEligibility patientInfo={patientInfo} hardRefresh={hardRefresh} />
      )}

      {/*Insurance*/}
      {hasEligibilityCheckButtonFeature && hasEnableCopyDemographics && (<InsuranceList data={patientInfo.eligibilityInfo?.eligibilityInsurance as any[] ?? []}  />)}

      {/* Contact */}
      {checkSectionIsEmpty(contactSection) ? null : (
        <InfoList
          classes={classes}
          patientData={patientInfo}
          sectionName={'Contact'}
          sectionFields={contactSection}
          key={'infoList-contact'}
          hardRefresh={hardRefresh}
          showLockIcon={showLockIcon}
        />
      )}

      <InfoList
        classes={classes}
        patientData={patientInfo}
        sectionName={'Future Appointment'}
        key={'infoList-futureAppointment'}
        hardRefresh={hardRefresh}
      />

      {/* Notes */}
      <InfoList
        classes={classes}
        patientData={patientInfo}
        sectionName={'Notes'}
        key={'infoList-notes'}
        hardRefresh={hardRefresh}
      />

      {/* General Detail */}
      {checkSectionIsEmpty(filteredGeneralInfoSection) ? null : (
        <InfoList
          classes={classes}
          patientData={patientInfo}
          sectionName={'General Detail'}
          sectionFields={filteredGeneralInfoSection}
          key={'infoList-generalDetail'}
          hardRefresh={hardRefresh}
        />
      )}

      {/* Family Members */}
      {patientInfo.familyMembers && patientInfo.familyMembers.length > 0 ? (
        <InfoList
          classes={classes}
          patientData={patientInfo}
          sectionName={'Family Members'}
          setKeyPatients={setKeyPatients}
          setClickedFamilyRows={setClickedFamilyRows}
          originalPatientId={originalPatientId}
          key={'infoList-familyMembers'}
          hardRefresh={hardRefresh}
        />
      ) : null}

      {/* Panels */}
      {/* Hide Face sheet Panel section for Coordinator Persona. */}
      {/* Show panel section for coordinator persona - VBC-2724 */}
      {patientInfo.panels && patientInfo.panels.length > 0 ? (
        <InfoList
          classes={classes}
          patientData={patientInfo}
          sectionName={'Panels'}
          key={'infoList-panels'}
          hardRefresh={hardRefresh}
          setDeListPatientDialogState={setDeListPatientDialogState}
        />
      ) : null}

      {/* De-List Patient Dialog */}
      <DeListPatientDialog
        deListPatientDialogState={deListPatientDialogState}
        dialogContent={{
          id: 'DeListPatientDialog',
          title: 'Are you sure?',
          description: getDeListDialogContent(),
        }}
        handleClose={onDeListPatientDialogClose}
        onConfirm={onDeListPatientDialogConfirm}
      />
    </div>
  );
};

// -- styled tooltip
const StyledTooltip = withStyles({
  tooltip: {
    maxWidth: 360,
    backgroundColor: 'rgba(67, 67, 67, 0.8)',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 600,
    padding: '6px 12px',
    lineHeight: '20px',
  },
  popper: {
    top: '-30px !important',
    left: '-45px !important',
  },
})(Tooltip);
