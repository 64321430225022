import React from 'react';
import { Typography } from '@material-ui/core';
import {
  ArrowBackIos as ArrowBackIcon,
  ArrowForwardIos as ArrowForwardIcon,
} from '@material-ui/icons';
import { PatientFaceInfo } from '../../../../../../../../../app.types.generated';
import { ButtonMediumGhostIcon } from '../../../../../../../../../components/Buttons';
import {PatientFilterComponentIDs} from "../../../../../../../../../enum/store";
import { getAge } from '../../../../../../../../../sharePipe/getAge';
import { getFullGender } from '../../../../../../../../../sharePipe/getFullGender';
import {usePatientFilterStore} from "../../../../../../../../../store/features";
import {CareGapContext} from "../../../../context";
import {
  useCareGapDrawerHeaderStyles,
  useECWCopyLabelStyles,
} from '../../Styles';
import { CloseIcon } from '../../../../../../../../../assets/Icons/ExportPatients';
import { EligibilityBadge } from '../../../../../../../../../components/EligibilityBadge';
import { LabelWithCopy } from '../../../../../../../../../components/LabelWithCopy';
import {CopyIcon} from "../../../../../../../../../assets/Icons/CareGaps";
import {CopyButton} from "../../../../../../../../../components/CopyButton";

type EligibilityBadgeType = {
  lastCheck: string;
  status: string;
  icon: JSX.Element;
};

enum EligibilityBadgeStatusType {
  internalActive = 'ActiveInternal',
  externalActive = 'ActiveExternal',
  rejected = 'Rejected',
  inactive = 'Inactive',
  active = 'Active',
}

interface CareGapDrawerHeaderTitleProps {
  patientInfo: PatientFaceInfo;
  disabledArrowForwardBtn: boolean;
  disabledArrowBackwardBtn: boolean;
  handleSwitchPatient: (event: React.MouseEvent) => void;
  clickedFamilyRows: boolean;
  handleClose: () => void;
}

export const CareGapDrawerHeaderTitle = (
  props: CareGapDrawerHeaderTitleProps
) => {
  const {
    patientInfo,
    disabledArrowForwardBtn,
    disabledArrowBackwardBtn,
    handleSwitchPatient,
    clickedFamilyRows,
    handleClose,
  } = props;

  const { patientFilterComponentId } = React.useContext(
      CareGapContext
  );

  const patientFilterStore = usePatientFilterStore(patientFilterComponentId ?? PatientFilterComponentIDs.ALL_PATIENTS);
  const currentFilter = patientFilterStore.formattedFiltersForQuery;

  console.log('selectedFilters' + patientFilterComponentId, currentFilter)


  const classes = useCareGapDrawerHeaderStyles();
  const classesECW = useECWCopyLabelStyles();

  const getPatientFirstInsuranceName = () => {
    if (patientInfo.insurances && patientInfo.insurances.length > 0) {
      return `${patientInfo.insurances[0].name}`;
    } else {
      return '';
    }
  };

  const clinicPatients = patientInfo.patients?.filter(({clinicId, patientId}) => {
    if (currentFilter?.clinicId?.length) {
      return currentFilter?.clinicId.includes(clinicId?.toString());
    } else {
      return true
    }
  })

  return (
    <div>
      <div className={classes.drawerHeader}>
        <div className={classes.drawerHeaderTopContainer}>
          <div>
            {
              (patientInfo.id && patientInfo.id.toString().length > 10 &&
                  patientInfo.id.toString().startsWith("9000"))
                ? (
                <span style={{
                  color: '#008479',
                  backgroundColor: '#E8F6F2',
                  fontSize: 10,
                  padding: '2px 5px',
                  marginRight: 5,
                  fontWeight: 600,
                }}>
          NEW
        </span>
            ) : null}
            <div className={classes.nameAndEligibilityBadgeContainer}>

              {/* patient lastName, firstName */}
              <Typography variant="h4" className={classes.drawerHeaderTitle}>
                {`${patientInfo.lastName}, ${patientInfo.firstName}`}
              </Typography>

              {/* Eligibility Badge */}
              <EligibilityBadge
                  patientInfo={patientInfo}
                  pageToDisplay="PatientFaceSheet"
              />
            </div>
          </div>
          {/* switch patient arrow buttons */}
          <div className={classes.drawerHeaderArrows}>
            {clickedFamilyRows ? (
                <ButtonMediumGhostIcon
                    role="closeFamilyPatient"
                    variant="text"
                    onClick={handleClose}
                >
                  <CloseIcon/>
                </ButtonMediumGhostIcon>
            ) : (
                <>
                  <ButtonMediumGhostIcon
                      role="prevPatient"
                      variant="text"
                      disabled={disabledArrowBackwardBtn}
                      onClick={(event) => {
                        handleSwitchPatient(event);
                      }}
                  >
                    <ArrowBackIcon
                        className={
                          disabledArrowBackwardBtn
                              ? classes.arrowIcon + ' ' + classes.arrowIconDisabled
                              : classes.arrowIcon + ' ' + classes.arrowIconEnabled
                        }
                    />
                  </ButtonMediumGhostIcon>

                  <ButtonMediumGhostIcon
                      role="nextPatient"
                      variant="text"
                      disabled={disabledArrowForwardBtn}
                      onClick={(event) => {
                        handleSwitchPatient(event);
                      }}
                  >
                    <ArrowForwardIcon
                        className={
                          disabledArrowForwardBtn
                              ? classes.arrowIcon + ' ' + classes.arrowIconDisabled
                              : classes.arrowIcon + ' ' + classes.arrowIconEnabled
                        }
                    />
                  </ButtonMediumGhostIcon>
                </>
            )}
          </div>
        </div>

        {/* patient id, dob, age and gender */}
        <div className={'drawerHeaderSubscriptContainer'}>
          {/* dob, age and gender */}
          <Typography className={classes.drawerHeaderSubscript}>
            <div className={classes.dobContainer}>({patientInfo.dateOfBirth}
            <div className={classes.icon}>
              <CopyButton textToCopy={patientInfo.dateOfBirth} />
            </div>
          </div>
            <div>{getAge(patientInfo.dateOfBirth)}y</div>
            <div>{getFullGender(patientInfo.gender)})</div>

          </Typography>
          {/* insurances */}
          <Typography variant="body1" className={classes.drawerHeaderSubscript}>
            <div>
              <span>Insurance: </span>
              {`${getPatientFirstInsuranceName()}`}
            </div>
          </Typography>

          {/* IDs: clinic ID, patient ID, ecw ID */}
          <div className={classes.clinicPatientIDPairContainer}>
            {clinicPatients?.map(({clinicId, patientId}, idx, array) => {
              return <div key={idx} className={classes.clinicPatientIDPairItemContainer}>
                <Typography variant="body1" className={classes.clinicPatientIDPairItemClinicIDTitle}>
                  <span>Clinic ID: </span>
                  {`${clinicId} `}
                </Typography>

                {/*For the new patient. Show the clinic only*/}
                <div className={classes.clinicPatientIDPairItemPatientIDContainer}>
                  {patientInfo.id && patientInfo.id.toString().length > 10 ? null : (<LabelWithCopy label={`Patient ID: ${patientId}`} />)}
                  {idx === array.length - 1 ? '' : '  ,'}
                </div>
              </div>
            })}

            {patientInfo.ecwPatientId && (
                <LabelWithCopy
                    label={`ECW ID: ${patientInfo.ecwPatientId}`}
                    customClasses={classesECW}
                />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


